import React, { useState, useEffect } from 'react';
import {
    Button, Container, Grid, CircularProgress, TableContainer, Table, TableHead,
    TableRow, TableCell, TableBody, Paper, TablePagination, IconButton,
    TextField,
    Box,
    Typography,
} from '@mui/material';
import DownloadIcon1 from "@mui/icons-material/Download";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import axios from 'axios';
import { PartnerArrowIcon } from '../../res/NewWebIcons';
import PayoutReportInvoice from './PayoutReportInvoice';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';

const NewPayOutReport = () => {
    const [payOutData, setPayOutData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        fetchPayOutData(page, fromDate, toDate);
    }, [page]);

    const fetchPayOutData = async (currentPage = 0, startDate = '', endDate = '') => {
        setLoading(true);
        const jwtToken = localStorage.getItem('jwtToken');
        try {
            const response = await axios.get(`https://api.ipaisa.co.in/myapp/auth/allPayoutByUser`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
                params: {
                    page: currentPage,
                    size: rowsPerPage,
                    ...(startDate && { fromDate: startDate }),
                    ...(endDate && { toDate: endDate })
                }
            });
            setPayOutData(response.data.body.data || []);
        } catch (error) {
            console.error('Error fetching PayOut data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        setPage(0); // Reset to the first page on new search
        fetchPayOutData(0, fromDate, toDate); // Pass date parameters when search is triggered
    };

    const handleDownloadInvoice = async (data) => {
        const receiptElement = document.getElementById(`receipt-${data.txnReferenceId}`);

        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${data.txnReferenceId}`);
            return;
        }

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
            filename: `Payout Receipt-${data.txnReferenceId}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
        };

        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };

    const handleDownloadCSV = async () => {
        const jwtToken = localStorage.getItem('jwtToken');
        try {
            const response = await axios.get(`https://api.ipaisa.co.in/myapp/auth/allPayoutByUser`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
                params: {
                    purpose: 'csv',
                    ...(fromDate && { fromDate }),
                    ...(toDate && { toDate })
                }
            });

            const headers = ["Sr. No.", "Txn ID", "Utr No.", "Account No.", "Account Name", "Date", "Opening Balance", "Amount", "Closing Balance", "Status"];

            // Map the response data to CSV format
            const csvRows = [
                headers.join(","), // Header row
                ...response.data.body.data.map((row, index) => [
                    index + 1 + page * rowsPerPage,                // Sr. No.
                    row.txnReferenceId || 'N/A',                   // Txn ID
                    row.externalRef || 'N/A',                      // Utr No.
                    row.payeeAccount || 'N/A',                     // Account No.
                    row.payeeName || 'N/A',                        // Account Name
                    row.timestamp ? new Date(row.timestamp).toLocaleString() : 'N/A', // Date
                    row.walletOpeningBalance?.toFixed(2) || 'N/A', // Opening Balance
                    row.txnValue || 'N/A',                         // Amount
                    row.walletClosingBalance?.toFixed(2) || 'N/A', // Closing Balance
                    row.status || 'N/A'                            // Status
                ].join(","))
            ].join("\n");

            // Convert the CSV data to a Blob and trigger the download
            const blob = new Blob([csvRows], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "payout_report.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    const handlePageChange = (event, newPage) => setPage(newPage);
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Report</Typography>
                    <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Payout Report</Typography>
            </Grid>
                <Grid style={{marginTop:'1%'}}>
                    <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Payout Report</Typography>
                </Grid>
            <Paper elevation={2} style={{ padding: '2rem', borderRadius: '8px', marginTop: '2%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="From Date"
                            type="date"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            label="To Date"
                            type="date"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '3%' }}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                            <Button
                                onClick={handleSearch}
                                sx={{
                                    backgroundColor: '#027BFE',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: '120px',
                                }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    setFromDate('');
                                    setToDate('');
                                    setPage(0);
                                    fetchPayOutData(0, '', ''); // Reset data
                                }}
                                sx={{
                                    backgroundColor: 'gray',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: '120px',
                                }}
                            >
                                Clear
                            </Button>
                            <Button
                                onClick={handleDownloadCSV}
                                sx={{
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    height: '56px',
                                    textTransform: 'none',
                                    borderRadius: '10px',
                                    width: '150px',
                                }}
                                startIcon={<DownloadIcon1 />}
                            >
                                Download CSV
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <TableContainer component={Paper} sx={{ mt: 3, borderRadius: 2 }}>
                        <Table>
                            <TableHead sx={{ bgcolor: '#027BFE' }}>
                                <TableRow>
                                    {['Sr. No.', 'Txn ID', 'Utr No.', 'Account No.', 'Account Name', 'Date', 'Opening Balance', 'Amount', 'Closing Balance', 'Status', 'Invoice'].map(header => (
                                        <TableCell align="center" key={header} sx={{ color: 'white' }}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payOutData.length > 0 ? payOutData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                        <TableCell>{row.externalRef || 'N/A'}</TableCell>
                                        <TableCell>{row.txnReferenceId || 'N/A'}</TableCell>
                                        <TableCell>{row.payeeAccount || 'N/A'}</TableCell>
                                        <TableCell>{row.payeeName || 'N/A'}</TableCell>
                                        <TableCell>{row.timestamp ? new Date(row.timestamp).toLocaleString() : 'N/A'}</TableCell>
                                        <TableCell>{row.walletOpeningBalance?.toFixed(2) || 'N/A'}</TableCell>
                                        <TableCell>{row.txnValue || 'N/A'}</TableCell>
                                        <TableCell>{row.walletClosingBalance?.toFixed(2) || 'N/A'}</TableCell>
                                        {/* <TableCell>{row.status || 'N/A'}</TableCell> */}
                                        <TableCell
                                            align="start"
                                            style={{
                                                color: row.status.toLowerCase() === 'transaction successful'
                                                    ? 'green'
                                                    : row.status.toLowerCase() === 'refund'
                                                    ? 'darkgoldenrod'
                                                    : row.status.toLowerCase() === 'pending'
                                                    ? 'orange'
                                                    : 'red',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {row.status.toLowerCase() === 'transaction successful'
                                                ? 'Successful'
                                                : row.status.toLowerCase() === 'refund'
                                                ? 'Refund'
                                                : row.status.toLowerCase() === 'pending'
                                                ? 'Pending'
                                                : 'Failed'}
                                        </TableCell>
                                        <TableCell align="center">
                                            <DownloadIcon1
                                                onClick={() => handleDownloadInvoice(row)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </TableCell>
                                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                            <Grid id={`receipt-${row.txnReferenceId}`}>
                                                <PayoutReportInvoice data={row} />
                                            </Grid>
                                        </Grid>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={11} align="center">No Data Available</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={payOutData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        backIconButtonProps={{
                            disabled: page === 0,
                            children: <NavigateBeforeIcon />
                        }}
                        nextIconButtonProps={{
                            disabled: payOutData.length < rowsPerPage,
                            children: <NavigateNextIcon />
                        }}
                    />
                </>
            )}
        </Container>
    );
};

export default NewPayOutReport;


