import React from 'react';
import { Button, Grid, Typography, Dialog, Box } from '@mui/material';
import { BbpsBIcon } from '../../res/NewWebIcons';
import logo from "../Assets/login/iPaisaLogo.png";
import { FailedPopIcon1 } from '../../res/icons';

const ComplaintRegisFail = ({ handleClose, data }) => {
    // Extracting values from the data prop, using optional chaining for safety
    console.log(data);
    // const referenceId = data?.referenceId || 'N/A';
    const responseCode = data?.responseCode || 'N/A';
    const responseReason = data?.responseReason || 'N/A';
    const txnReferenceId = data?.referenceId || 'N/A';
    const status = 'Failure'; // Since this is a failure component, status is set to "Failure"

    // Getting the current date and time
    const currentDateTime = new Date().toLocaleString();

    // Styles for failure icon
    const failureIconStyle = {
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 70,
        height: 65,
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '600px',
                    padding: '1rem'
                }
            }}
        >
            <Grid container>
                {/* Icon Section */}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={logo} alt="iPaisa Logo" style={{ height: 54, width: 54, backgroundColor: 'transparent', borderRadius: '50%' }} />
                    <Box sx={failureIconStyle}>
                        <FailedPopIcon1 />
                    </Box>
                    <BbpsBIcon />
                </Grid>

                {/* Title Section */}
                <Grid container justifyContent="center" style={{ marginTop: '4%' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '22px', color: 'red' }}>
                        Complaint Registration Failed
                    </Typography>
                </Grid>

                {/* Details Section */}
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '70%', marginTop: '6%' }}>
                    {/* Left Side (Labels) */}
                    <Grid item container direction="column" spacing={1} xs={6}>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint Date & Time</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Response Reason</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Transaction Ref ID</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Response Code</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint Status</Typography>
                    </Grid>

                    {/* Right Side (Values) */}
                    <Grid item container direction="column" spacing={1} xs={6}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{currentDateTime}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{responseReason}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{txnReferenceId}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{responseCode}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{status}</Typography>
                    </Grid>
                </Grid>

                {/* Close Button */}
                <Grid container justifyContent="flex-end" style={{ marginTop: '2rem' }}>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ComplaintRegisFail;
