// import React from 'react'

// const RetailerLoanPay = () => {
//   return (
//     <div style={{display:'flex', justifyContent:'center', alignContent:'center', marginTop:'25%', fontWeight:'bold', fontSize:'20px' }}>
//       Coming Soon...
//     </div>
//   )
// }

// export default RetailerLoanPay

// import React from 'react'

// const RetailerToll = () => {
//   return (
//     <div style={{display:'flex', justifyContent:'center', alignContent:'center', marginTop:'25%', fontWeight:'bold', fontSize:'20px' }}>
//       Coming Soon...
//     </div>
//   )
// }

// export default RetailerToll

import React, { useState } from "react";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  Box,
  MenuItem,
  CardMedia,
} from "@mui/material";
import { useFormik } from "formik";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
import logo from "../Assets/login/iPaisaLogo.png";
import { NewBBPSIcon } from "../../res/NewWebIcons";

export default function RetailerLoanPay() {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showLoanAccountNumber, setShowLoanAccountNumber] = useState(false);

  const formik = useFormik({
    initialValues: {
      operator: "",
      consumerNumber: "",
      loanAccountNumber: "",
    },
    onSubmit: (values) => {
      // Perform form submission logic here
      setSubmitSuccess(true);
    },
  });

  const handleConsumerNumberChange = (e) => {
    formik.handleChange(e);
    setShowLoanAccountNumber(e.target.value.trim() !== '');
  };

  const validateField = (field) => {
    if (formik.touched[field] && !formik.values[field]) {
      if (field === 'operator') return "Operator is required";
      if (field === 'consumerNumber') return "Loan Payment Mode is required";
      if (field === 'loanAccountNumber' && showLoanAccountNumber) return "Loan Account Number is required";
    }
    return "";
  };

  const inputHeight = '56px';

  return (
    <Container maxWidth="lg" style={{ marginTop: '12%', marginBottom: '3%' }}>
      <Box mt={4} p={4} boxShadow={2} bgcolor="#DDF1F5" borderRadius={8}
        sx={{
          background: 'linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)',
          color: 'white',
        }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{ backgroundColor: "transparent", borderRadius: "50%", marginBottom: '4%' }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" }, // Adjusted to ensure space between logo and text
              }}
            />
            <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '35px' }}>
              Pay Loan EMI
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: '20px', marginTop: '5%' }}>
              Streamline your routine with hassle-free loan EMI payments, ensuring smooth financial management every time.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography variant="h4" color="#027BFE" sx={{ fontWeight: 'bold', fontSize: '30px' }}>
                Pay Loan EMI
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <NewBBPSIcon/>
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  select
                  fullWidth
                  name="operator"
                  label="Lender"
                  value={formik.values.operator}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(validateField('operator'))}
                  helperText={validateField('operator')}
                  style={{ marginTop: "20px" }}
                >
                  <MenuItem value="">Select Lender</MenuItem>
                  <MenuItem value="Bajaj Finserv">Bajaj Finserv</MenuItem>
                  <MenuItem value="HDFC Bank">HDFC Bank</MenuItem>
                  <MenuItem value="State Bank of India (SBI)">State Bank of India (SBI)</MenuItem>
                  <MenuItem value="Kotak Mahindra Bank">Kotak Mahindra Bank</MenuItem>
                  <MenuItem value="Capital First">Capital First</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  type="text"
                  label="Loan Payment Mode"
                  name="consumerNumber"
                  value={formik.values.consumerNumber}
                  onChange={handleConsumerNumberChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(validateField('consumerNumber'))}
                  helperText={validateField('consumerNumber')}
                  style={{ marginTop: "20px" }}
                />

                {showLoanAccountNumber && (
                  <TextField
                    fullWidth
                    type="text"
                    label="Loan Account Number"
                    name="loanAccountNumber"
                    value={formik.values.loanAccountNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(validateField('loanAccountNumber'))}
                    helperText={validateField('loanAccountNumber')}
                    style={{ marginTop: "20px" }}
                  />
                )}

                <Button
                  fullWidth
                  type="submit"
                  style={{
                    marginTop: "4%",
                    backgroundColor: '#027BFE',
                    color: 'white',
                    textTransform: 'none',
                    height: inputHeight,
                    fontSize: 20
                  }}
                >
                  Get Payable Amount
                </Button>
                {submitSuccess && (
                  <Typography
                    variant="body2"
                    style={{ color: "green", marginTop: "20px" }}
                  >
                    Bill successfully submitted!
                  </Typography>
                )}
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
