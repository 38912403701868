import React, { useState, useEffect } from "react";
import { CardMedia, Container, Grid, Typography } from '@mui/material';
import "@fontsource/open-sans";
import imagePath from '../Assets/Dashboard/urban-202.gif'; // Update the path as needed
import { AddCardIcon, AUBankIcon, AxisBankIcon, IDFCBankIcon, UnionBankIcon, YesBankIcon } from "../../res/icons";
import { Button, Card, CardContent, Paper } from "@material-ui/core";


const Dashboard2 = () => {
  const userType = localStorage.getItem("uType");
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const handleViewDetails = (index) => {
      setActiveCardIndex(index);
  };

  const cardsData = [
    // {
    //     bankName: "YES BANK",
    //     company: "EDSOM FINTECH PVT.LTD.",
    //     accountNo: "120664700000021",
    //     ifsc: "YESB0000008",
    //     branch: "Prabhat Road, Pune",
    //     icon: YesBankIcon, // Use the desired icon
    //     color: "#0002D8",
    // },
    {
        bankName: "UNION BANK",
        company: "EDSOM FINTECH PVT.LTD.",
        accountNo: "583801010050602",
        ifsc: "UBIN0558389",
        branch: "Baner, Pune",
        icon: UnionBankIcon, // Use the desired icon
        color: "#D81F40"
    },
    {
        bankName: "AU SMALL Finance Bank",
        company: "EDSOM FINTECH PVT.LTD.",
        accountNo: "2402235358748585",
        ifsc: "AUBL0002353",
        branch: "Prabhat Road, Pune",
        icon: AUBankIcon, // Use the desired icon
        color: "#660F59"
    },
    {
        bankName: "AXIS Bank",
        company: "EDSOM FINTECH PVT.LTD.",
        accountNo: " 919020076135843",
        ifsc: "UTIB0002754",
        branch: "Balewadi, Pune",
        icon: AxisBankIcon, // Use the desired icon
        color: "#721324"
    },
    {
        bankName: "IDFC Bank",
        company: "EDSOM FINTECH PVT.LTD.",
        accountNo: "10066469212",
        ifsc: "IDFB0041433",
        branch: "Balewadi, Pune",
        icon: IDFCBankIcon, // Use the desired icon
        color: "#991F35"
    },
  ];

  return (
    <Container style={{padding:'2rem'}}>
      <Grid
        container
        sx={{
          width: '670px',
          height: '110px',
          top: '100px',
          left: '30px',
          gap: '0px',
          opacity: 1,
          position: 'relative', // Change to relative
          backgroundColor: '#3F64E4',
          borderRadius: '16px',
          marginBottom: '20%'
        }}
      >
        <Grid
          container
          sx={{
            width: '294.56px',
            height: '68px',
            marginTop: '4%',
            marginLeft: '4%',
            gap: '8px',
            position: 'absolute',
            color: '#FFFFFF',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '28px',
              fontWeight: 700,
              lineHeight: '38.13px',
              textAlign: 'left',
            }}
          >
            Hello Yash!
          </Typography>
        </Grid>

        <Grid
          container
          sx={{
            width: '350px',
            height: '68px',
            marginTop: '10%',
            marginLeft: '4%',
            gap: '8px',
            position: 'absolute',
            color: '#FFFFFF',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '21.79px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
          >
            Welcome, Admin: Ready to Take Charge
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            width: '124.81px',
            height: '79px',
            marginTop: '3%',
            marginLeft: '80%',
            gap: '0px',
            opacity: 0.8,
            position: 'absolute',
          }}
        >
          <CardMedia
            component="img"
            src={imagePath} // Path to the image
            alt="Descriptive Alt Text" // Provide a descriptive alt text for accessibility
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Adjust if needed
            }}
          />
        </Grid>

      </Grid>
      <Grid style={{marginTop:'3%'}}>
                    <Grid spacing={2} direction="row" justifyContent="center">
                        <Paper
                            elevation={4}
                            style={{
                                marginTop: "2%",
                                padding: "0.6%",
                                textAlign: "center",
                                backgroundColor: "#FFFFFF",
                                borderRadius: 20,
                                width: "calc(100% - 3%)",
                                marginBottom: "4%",
                                justifyContent: "space-around",
                                alignItems: "center",
                                padding: "2%"
                            }}
                        >
                            <Grid
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: 'space-between',
                                    alignItems: "center",
                                }} >
                                <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                    My Banks
                                </Typography>
                                {userType === "ADMIN" && (
                                    <Typography style={{cursor:'pointer'}}><AddCardIcon />
                                    </Typography>
                                    )}
                            </Grid>
                            <Grid
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: 'space-between',
                                    // padding:'2%'
                                }} >
                                {cardsData.map((card, index) => (
                                    <Card
                                        key={index}
                                        style={{
                                            backgroundColor: card.color,
                                            width: activeCardIndex === index ? '25%' : '12%',
                                            borderRadius: "20px",
                                            display: "flex",
                                            flexDirection: activeCardIndex === index ? "column" : "column",
                                            alignItems: activeCardIndex === index ? "flex-start" : "center",
                                            textAlign: activeCardIndex === index ? "start" : "center",
                                            padding: "10px",
                                            marginBottom: "16px",
                                            marginTop: "16px",
                                            minHeight: '100%',
                                            transition: 'width 0.3 ease-in-out',
                                        }}
                                    >
                                        <CardContent style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                            <Grid style={{ display: "flex", alignItems: "center", flexDirection: activeCardIndex === index ? "row" : "column" }}>
                                                <card.icon style={{ marginRight: activeCardIndex === index ? '10px' : '0', color: '#FFFFFF' }} />
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        color: "#FFFFFF",
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        paddingLeft: activeCardIndex === index ? "5%" : "0",
                                                        marginTop: activeCardIndex === index ? "0" : "10px"
                                                    }}
                                                >
                                                    {card.bankName}
                                                </Typography>
                                            </Grid>
                                            {activeCardIndex === index && (
                                                <>
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            marginTop: '5%',
                                                            color: "yellow",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {card.company}
                                                    </Typography>
                                                    <Typography variant="body1" style={{ color: "white", fontSize: '15px', paddingTop: '4%', fontWeight: 'bold' }}>
                                                        Account No: {card.accountNo}
                                                    </Typography>
                                                    <Typography variant="body1" style={{ color: "white", fontSize: '13px', paddingTop: '2%' }}>
                                                        IFSC: {card.ifsc}
                                                    </Typography>
                                                    <Typography variant="body1" style={{ color: "white", fontSize: '13px', paddingTop: '2%' }}>
                                                        Branch: {card.branch}
                                                    </Typography>
                                                </>
                                            )}
                                            {activeCardIndex !== index && (
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleViewDetails(index)}
                                                    style={{
                                                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                                        color: 'white',
                                                        border: "1px solid white",
                                                        marginTop: "auto",
                                                        alignSelf: "center",
                                                        fontSize: "10px",
                                                    }}
                                                >
                                                    View Details
                                                </Button>
                                            )}
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard2;
