import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Grid, Container, TablePagination
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BeneficiaryReport = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [hasMore, setHasMore] = useState(false); // Track if there are more records
    const noContentToastShown = useRef(false);

    useEffect(() => {
        fetchBeneficiaryTransactions();
    }, [page, rowsPerPage]);

    const fetchBeneficiaryTransactions = async () => {
        try {
            const token = localStorage.getItem('jwtToken');
            if (!token) {
                setError('No token found');
                setLoading(false);
                return;
            }

            const response = await axios.get(`https://api.ipaisa.co.in/myapp/admin/getAllBeniTxn?page=${page}&size=${rowsPerPage}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            console.log("API Response:", response.data); // Check API response structure

            if (response.data.statusCode === "OK") {
                setTransactions(response.data.body); // Update transactions
                setTotalRows(response.data.body.length); // Update totalRows based on the array length
                setHasMore(response.data.body.length === rowsPerPage); // Determine if there are more records
                noContentToastShown.current = false;
            } else if (response.data.statusCode === "NO_CONTENT" && !noContentToastShown.current) {
                toast.info(response.data.body.message || 'No Content Available');
                setTransactions([]);
                setTotalRows(0);
                noContentToastShown.current = true;
            }
        } catch (err) {
            console.error("Error fetching transactions:", err); // Log errors for debugging
            setError('Error fetching transactions');
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const handleChangePage = (event, newPage) => {
        if (hasMore || newPage < page) {
            setPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container sx={{ marginTop: '10%' }}>
            <ToastContainer />
            <Typography variant="h4" gutterBottom>
                Beneficiary Transaction Report
            </Typography>

            {loading ? (
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <>
                    <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px', marginTop: '10%' }}>
                        <Table>
                            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }} align="center">ID</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Account No</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Status</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Opening Balance</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Charges</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Closing Balance</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((txn) => (
                                    <TableRow key={txn.id}>
                                        <TableCell align="center">{txn.id}</TableCell>
                                        <TableCell align="center">{txn.accountNo}</TableCell>
                                        <TableCell align="center">{txn.status}</TableCell>
                                        <TableCell align="center">{Number(txn.wOpeningBalance).toFixed(2)}</TableCell>
                                        <TableCell align="center">{txn.charges}</TableCell>
                                        <TableCell align="center">{Number(txn.wClosingBalance).toFixed(2)}</TableCell>
                                        <TableCell align="center">{formatDate(txn.createdAt)}</TableCell> {/* Formatted date */}
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={hasMore ? totalRows + rowsPerPage : totalRows} // Adjust count based on hasMore
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Container>
    );
};

export default BeneficiaryReport;
