import React, { useState, useEffect } from 'react';
import {
    Button,
    Container,
    Grid,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TextField,
    Box,
    Typography,
    InputBase,
    IconButton,
    Pagination,
    TablePagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from "@mui/icons-material/Search";
import axios from 'axios';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.png';
import { DownloadIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import DownloadIcon1 from "@mui/icons-material/Download";
import html2pdf from 'html2pdf.js';
import PayinReportInvoice from './PayinReportInvoice';
import { CSVLink } from 'react-csv';


const useStyles = makeStyles(() => ({
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
}));

const NewPayIn = () => {

    const [payInData, setPayInData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0); // Total number of items for pagination
    const [isNextDisabled, setIsNextDisabled] = useState(false); // State to manage next button state
    const [statusCodeValue, setStatusCodeValue] = useState(null); // State to manage status code

    const classes = useStyles();
    useEffect(() => {
        fetchPayInData(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);

    const fetchPayInData = async () => {
        const jwtToken = localStorage.getItem('jwtToken');
        try {
            const response = await fetch(`https://api.ipaisa.co.in/myapp/auth/easTxnDetails?pageNumber=${currentPage + 0}`, {
                headers: { Authorization: `Bearer ${jwtToken}` },
            });

            if (response.status === 200) {
                const data = await response.json();

                // Check if data.body and data.body.data are defined
                if (data.body.list && data.body.list) {
                    setFilteredData(data.body.list); // Set the filtered data
                    setTotalItems(data.body.list.length); // Update total items
                } else {
                    setFilteredData([]); // Reset filtered data to an empty array if undefined
                    setTotalItems(0); // Reset total items to 0 if no data is available
                }

                // Set the status code value
                const responseStatusCode = data.statusCodeValue; // Adjust based on actual response structure
                setStatusCodeValue(responseStatusCode);
                console.log("Received statusCode:", responseStatusCode);

                // If statusCode is 204, log a message
                if (responseStatusCode === 204) {
                    console.log("No content available.");
                    const responseStatusCode = data.statusCodeValue; // Adjust based on actual response structure
                    setStatusCodeValue(responseStatusCode);
                    console.log("Received statusCode:", responseStatusCode);
                }
            } else {
                console.error('Error fetching PayIn data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching PayIn data:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleDownloadInvoice = async (data) => {
        const receiptElement = document.getElementById(`receipt-${data.txnid}`);

        if (!receiptElement) {
            console.error('Receipt element not found:', `receipt-${data.txnid}`);
            return;
        }

        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
            filename: `Payin Receipt-${data.txnid}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
        };

        try {
            await html2pdf().from(receiptElement).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF: ', error);
        }
    };


    const handleDownloadCSV = () => {
        const csvData = filteredData.map((row, index) => ({
            SrNo: index + 1,
            TxnID: row.txnid,
            UTRNo: row.easepayid,
            Date: formatDate(row.addedon),
            Amount: row.amount,
            Status: row.status === 'success' ? 'Successful' : 'Failed',
        }));
        return csvData;
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Pay In Report", 14, 10);
        doc.autoTable({
            head: [['Sr. No.', 'Txn ID', 'UTR No', 'Date', 'Amount', 'Status']],
            body: filteredData.map((row, index) => [
                index + 1,
                row.txnid,
                row.easepayid,
                formatDate(row.addedon),
                row.amount,
                row.status === 'success' ? 'Successful' : 'Failed'
            ])
        });
        doc.save('pay_in_report.pdf');
    };



    const handleNextPage = () => {
        if ((currentPage + 1) * rowsPerPage < totalItems) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };
    const formatDate = (dateTimeString) => {
        if (!dateTimeString) return '';
        const [datePart] = dateTimeString.split('T');
        const [year, month, day] = datePart.split('-');
        return `${day}-${month}-${year}`;
    };



    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress />
            </Grid>
        );
    }



    return (
        <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Report</Typography>
                <PartnerArrowIcon />
                <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Pay In Report</Typography>
            </Grid>

            <Grid>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
                    <Grid>
                        <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Pay In Report</Typography>
                    </Grid>

                    <Grid style={{display:'flex', gap:'3%'}}>
                        <Button 
                        style={{
                            textTransform: 'none',
                            backgroundColor: '#027BFE',
                            color: '#FFFFFF',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px 16px',
                            fontFamily: 'sans-serif',
                            fontSize: '14px',
                            whiteSpace: 'nowrap', // Ensure text stays in one line
                            gap: '5%',
                            height: '40px',
                        }}
                        onClick={handleDownloadPDF}> 
                        <DownloadIcon />
                        Download PDF File
                        </Button>
                        <CSVLink
                            data={handleDownloadCSV()}
                            filename={"pay_in_report.csv"}
                            target="_blank"
                        >
                            <Button 
                            style={{
                                textTransform: 'none',
                                backgroundColor: '#027BFE',
                                color: '#FFFFFF',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '8px 16px',
                                fontFamily: 'sans-serif',
                                fontSize: '14px',
                                whiteSpace: 'nowrap', // Ensure text stays in one line
                                gap: '5%',
                                height: '40px',
                            }}
                            ><DownloadIcon />
                            Download CSV File
                            </Button>
                        </CSVLink>
                    </Grid>
                </Grid>
            </Grid>




            <Grid style={{ marginTop: '3%' }}>


                <TableContainer component={Paper} sx={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                            <TableRow>
                                <TableCell style={{ color: 'white' }} align="start">Sr. No.</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Txn ID</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">UTR No</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Date</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Amount</TableCell>
                                <TableCell style={{ color: 'white' }} align="start">Status</TableCell>
                                <TableCell style={{ color: 'white' }} align="center">Invoice</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.length > 0 ? (
                                filteredData.map((row, index) => (
                                    <TableRow key={row.id} 
                                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell align="start">{index + 1}</TableCell>
                                        <TableCell align="start">{row.txnid}</TableCell>
                                        <TableCell align="start">{row.easepayid}</TableCell>
                                        <TableCell align="start">{formatDate(row.addedon)}</TableCell>
                                        <TableCell align="start">{row.amount}</TableCell>
                                        <TableCell align="start" style={{
                                            color: row.status === 'success' ? 'green' : 'red',
                                            fontWeight: 'bold',
                                        }}>
                                            {row.status === 'success' ? 'Successful' : 'Failed'}
                                        </TableCell>
                                        <TableCell align="center">
                                            <DownloadIcon1
                                                onClick={() => handleDownloadInvoice(row)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </TableCell>
                                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                            <Grid id={`receipt-${row.txnid}`}>
                                                <PayinReportInvoice data={row} />
                                            </Grid>
                                        </Grid>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">No Data Available</TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalItems} // Use totalItems instead of filteredData.length
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(event, newPage) => setCurrentPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setCurrentPage(0); // Reset to first page when rows per page changes
                    }}
                    nextIconButtonProps={{
                        disabled: statusCodeValue === 204,
                    }}
                    backIconButtonProps={{
                        disabled: currentPage === 0,
                    }}
                />
            </Grid>
        </Container>
    );
};

export default NewPayIn;
