import React, { useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, Paper } from '@mui/material';
import axios from 'axios';

const SearchAndFilters = ({ searchParams, setSearchParams, handleSearch }) => {
  const { userType, selectedUserId, searchTerm, fromDate, toDate } = searchParams;
  const [users, setUsers] = useState([]);
  const [availableUserTypes, setAvailableUserTypes] = useState([]);

  const handleUserTypeChange = async (event) => {
    const selectedType = event.target.value;
    setSearchParams((prev) => ({ ...prev, userType: selectedType, selectedUserId: null }));
    
    // Fetch users based on selected user type
    const jwtToken = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get(`https://api.ipaisa.co.in/myapp/admin/getAllPartner`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        params: { userrole: selectedType },
      });

      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Error fetching partners:', error);
    }
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prev) => ({ ...prev, [field]: value }));
  };

  const handleSearchClick = () => {
    handleSearch(searchParams);
  };

  useEffect(() => {
    const uType = localStorage.getItem('uType');
    if (uType === 'MASTERDISTRIBUTOR') {
      setAvailableUserTypes(['AREADISTRIBUTOR', 'RETAILER']);
    } else if (uType === 'AREADISTRIBUTOR') {
      setAvailableUserTypes(['RETAILER']);
    } else {
      setAvailableUserTypes(['AREADISTRIBUTOR', 'RETAILER']);
    }
  }, []);

  return (
    <Paper elevation={3} sx={{ padding: '3%', borderRadius: '8px', marginTop: '2%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>User Type</InputLabel>
            <Select value={userType} onChange={handleUserTypeChange} label="User Type">
              {availableUserTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type === 'AREADISTRIBUTOR' ? 'Area Distributor' : 'Retailer'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>User</InputLabel>
            <Select value={selectedUserId || ''} onChange={(e) => handleInputChange('selectedUserId', e.target.value)} label="User">
              {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  {user.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => handleInputChange('searchTerm', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            type="date"
            label="From Date"
            fullWidth
            variant="outlined"
            value={fromDate}
            onChange={(e) => handleInputChange('fromDate', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type="date"
            label="To Date"
            fullWidth
            variant="outlined"
            value={toDate}
            onChange={(e) => handleInputChange('toDate', e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSearchClick} disabled={!userType || !selectedUserId}>
            Search
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SearchAndFilters;
