import React, { useEffect } from 'react';
import { Button, Grid, Typography, Dialog, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GreenCheckmark from "../Assets/SuccessandFailedGif/GreenCheckmark.gif";
import SuccessSound1 from '../Assets/SuccessSounds/SuccessSound1.mp3';
const ReverseMoneySucc = ({ handleClose }) => {
  // Function to format the current date and time
  const formatDate = (date) => {
    const day = new Intl.DateTimeFormat('en-GB', { day: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(date);
    const year = new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(date);
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const time = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
    return `${day} ${month} ${year} at ${time}`;
  };
  const currentDateTime = formatDate(new Date());
  // Play the success sound when the popup opens
  useEffect(() => {
    const audio = new Audio(SuccessSound1);
    audio.play();
  }, []);
  return (
    <Dialog open={true} onClose={handleClose}>
      <Grid>
        <Grid style={{ padding: '2rem' }}>
          {/* Close Icon */}
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Grid>
          {/* Success Icon */}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={GreenCheckmark}
              style={{ display: 'flex', justifyContent: 'center', height: '30%', width: '30%', borderRadius: '50%' }}
              alt="Success Gif"
            />
          </Box>
          {/* Success Message */}
          <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Money Reversed Successfully!
            </Typography>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '14px', color: '#27984C' }}>
              {currentDateTime}
            </Typography>
          </Grid>
          {/* Description */}
          <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
            <Typography color="textSecondary" style={{ fontSize: '15px', textAlign: 'center' }}>
              Your Money has been Reversed successfully in your Wallet.
            </Typography>
          </Grid>
          {/* OK Button */}
          <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '6%', marginBottom: '4%' }}>
            <Button
              style={{ color: 'white', backgroundColor: '#185ABD', width: '20%' }}
              onClick={handleClose}
            >
              OK
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default ReverseMoneySucc;