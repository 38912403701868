import React, { useState } from 'react';
import { Container, Grid, Paper, Typography, Box, Modal, Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import RouterIcon from '@mui/icons-material/Router';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import mobilerecharge from '../Assets/Services/app_shortcut_24dp_FILL0_wght400_GRAD0_opsz24 1mobrecharge.svg';
import electricbill from '../Assets/Services/emoji_objects_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import dthbill from '../Assets/Services/tv_24dp_FILL0_wght400_GRAD0_opsz24 1.png';
import gascylinder from '../Assets/Services/propane_tank_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import schoolimage from '../Assets/Services/school_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import waterbill from '../Assets/Services/water_drop_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import payloans from '../Assets/Services/real_estate_agent_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import aprtments from '../Assets/Services/apartment_24dp_FILL0_wght400_GRAD0_opsz24 1.svg';
import postpaidrecharge from '../Assets/Services/postpaidrecharge.svg';
import landline from '../Assets/Services/landline.svg';
import toll from '../Assets/Services/toll.svg';
import metro from '../Assets/Services/metro.svg'; // Replace with actual path
import pipedgas from '../Assets/Services/pipedgas.svg'; // Replace with actual path
import municipaltax from '../Assets/Services/muncipal tax.svg'; // Replace with actual path
import fastag from '../Assets/Services/fastag.svg'; // Replace with actual path
import cabletv from '../Assets/Services/cabletv.svg'; // Replace with actual path
import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '../Assets/Services/CreditCardIcon.svg';
import { useNavigate } from 'react-router-dom';

const services = [
  { name: 'Mobile Recharge', icon: <img src={mobilerecharge} alt="Mobile Recharge" style={{ width: 40, height: 40 }} />, onClick: 'handleRechargeClick' },
  { name: 'Credit Card Bill', icon: <img src={CreditCardIcon} alt='CreditCardIcon' style={{ width: 40, height: 40 }} />, onClick: 'handleCreditCardClick'},
  { name: 'Electricity Bill', icon: <img src={electricbill} alt="Electricity Bill" style={{ width: 40, height: 40 }} />, onClick: 'handleRElectricityRechargeClick' },
  { name: 'Recharge DTH', icon: <img src={dthbill} alt="Recharge DTH" style={{ width: 40, height: 40 }} />, onClick: 'handleDthRechargeClick' },
  { name: 'Broadband', icon: <RouterIcon fontSize="large" />, onClick: 'handleBroadBandClick' },
  { name: 'Gas Cylinder', icon: <img src={gascylinder} alt="Gas Cylinder" style={{ width: 40, height: 40 }} />, onClick: 'handleGasCylinderBookClick' },
  { name: 'Education Bill', icon: <img src={schoolimage} alt="Education Bill" style={{ width: 40, height: 40 }} />, onClick: 'handleREducationClick' },
  { name: 'Water Bill', icon: <img src={waterbill} alt="Water Bill" style={{ width: 40, height: 40 }} />, onClick: 'handleRWaterBillClick' },
  { name: 'Pay Loans', icon: <img src={payloans} alt="Pay Loans" style={{ width: 40, height: 40 }} />, onClick: 'handleRPayLoanClick' },
  { name: 'All Payment Services', icon: <MoreHorizIcon fontSize="large" />, isModalTrigger: true },
];

const allPaymentServices = [
  { name: 'Post Paid Recharge', icon: <img src={postpaidrecharge} alt="Post Paid Recharge" style={{ width: 40, height: 40 }} />, onClick: 'handlePostPaidRechargeClick' },
  { name: 'Landline', icon: <img src={landline} alt="Landline" style={{ width: 40, height: 40 }} />, onClick: 'handleLandlineClick' },
  { name: 'Pay Loans', icon: <img src={payloans} alt="Pay Loans" style={{ width: 40, height: 40 }} />, onClick: 'handleRPayLoanClick' },
  { name: 'Metro', icon: <img src={metro} alt="Metro" style={{ width: 40, height: 40 }} />, onClick: 'handleRMetroClick' },
  { name: 'Apartments', icon: <img src={aprtments} alt="Apartments" style={{ width: 40, height: 40 }} />, onClick: 'handleRApartmentsClick' },
  { name: 'Piped Gas', icon: <img src={pipedgas} alt="Piped Gas" style={{ width: 40, height: 40 }} />, onClick: 'handleRPipedGasClick' },
  { name: 'Municipal Tax', icon: <img src={municipaltax} alt="Municipal Tax" style={{ width: 40, height: 40 }} />, onClick: 'handleRMuncipalTaxClick' },
  { name: 'Fastag Recharge', icon: <img src={fastag} alt="Fastag Recharge" style={{ width: 40, height: 40 }} />, onClick: 'handleFastTagRechargeClick' },
  { name: 'Cable TV', icon: <img src={cabletv} alt="Cable TV" style={{ width: 40, height: 40 }} />, onClick: 'handleRCabletvClick' },
];

const PayBills = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNavigation = (handler) => {
    switch (handler) {
      case 'handleRechargeClick':
        navigate('/rrecharge');
        break;
      case 'handleCreditCardClick':
        navigate('/CreditCard');
        break;
      case 'handleRElectricityRechargeClick':
        navigate('/rectricityrecharge');
        break;
      case 'handleDthRechargeClick':
        navigate('/dthrecharge');
        break;
      case 'handleBroadBandClick':
        navigate('/rbroadband');
        break;
      case 'handleGasCylinderBookClick':
        navigate('/gascylinderbooking');
        break;
      case 'handleREducationClick':
        navigate('/reducationfees');
        break;
      case 'handleRWaterBillClick':
        navigate('/retailerwater');
        break;
      case 'handleRPayLoanClick':
        navigate('/retailerLoanPay');
        break;
      case 'handleRApartmentsClick':
        navigate('/rapartments');
        break;
      case 'handlePostPaidRechargeClick':
        navigate('/postpaidrecharge');
        break;
      case 'handleLandlineClick':
        navigate('/landline');
        break;
      case 'handleRMetroClick':
        navigate('/metro');
        break;
      case 'handleRPipedGasClick':
        navigate('/rpipegas');
        break;
      case 'handleRMuncipalTaxClick':
        navigate('/rmuncipaltax');
        break;
      case 'handleFastTagRechargeClick':
        navigate('/fasttagrecharge');
        break;
      case 'handleRCabletvClick':
        navigate('/retailerCableTv');
        break;
      default:
        break;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: '8%', marginBottom: '3%' }}>
      <Paper elevation={2} sx={{ padding: 4, minHeight: 100, height: '540px', borderRadius: 8 }}>
        <Typography variant="h4" align="left" marginTop={1} gutterBottom>
          Recharge & Pay Bills on iPaisa
        </Typography>
        <Grid container spacing={1} sx={{ display: 'flex', flexWrap: 'wrap', marginTop: '2%' }}>
          <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-evenly', overflowX: 'auto', marginBottom: 0 }}>
            {services.slice(0, 5).map((service, index) => (
              <Grid item key={index} sx={{ width: 150, height: '220px' }}>
                <Paper
                  elevation={3}
                  style={{ padding: '20px', textAlign: 'center', backgroundColor: '#EDF6FF', height: '160px' }}
                  onClick={() => handleNavigation(service.onClick)}
                >
                  <Box display="flex" flexDirection="column" alignItems="center" height={153} marginTop={4}>
                    {service.icon}
                    <Typography variant="h6" style={{ marginTop: '10px' }}>
                      {service.name}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-evenly', overflowX: 'auto', marginTop: 0 }}>
            {services.slice(5).map((service, index) => (
              <Grid item key={index} sx={{ width: 150, height: '300px' }}>
                <Paper
                  elevation={3}
                  style={{ padding: '20px', textAlign: 'center', backgroundColor: '#EDF6FF', height: '160px' }}
                  onClick={service.isModalTrigger ? handleOpen : () => handleNavigation(service.onClick)}
                >
                  <Box display="flex" flexDirection="column" alignItems="center" height={153} marginTop={4}>
                    {service.icon}
                    <Typography variant="h6" style={{ marginTop: '10px' }}>
                      {service.name}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          All Payment Services
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {allPaymentServices.map((service, index) => (
              <Grid item xs={6} sm={4} key={index}>
                <Paper
                  elevation={3}
                  sx={{ padding: 2, textAlign: 'center', backgroundColor: '#EDF6FF' }}
                  onClick={() => handleNavigation(service.onClick)}
                >
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {service.icon}
                    <Typography variant="body1" style={{ marginTop: '10px' }}>
                      {service.name}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default PayBills;
