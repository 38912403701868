import axios from "axios";
import { toast } from "react-toastify";

// API call to fetch bank details
const getBankDetails = async (setBankDetails, setStatusMessage, setAddbenesuccess, setFailedtoaddbene) => {
  try {
    const token = localStorage.getItem("jwtToken");
    const response = await axios.get(
      "https://api.ipaisa.co.in/myapp/auth/getBankDetail",
      {
        headers: {      
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.statusCodeValue === 200) {
      const bankData = response.data.body || [];
      setBankDetails(bankData);
    } else if (response.data.statusCodeValue === 204) {
      setStatusMessage(response.data.body.status);
    }
  } catch (error) {
    console.log("Error fetching bank details:", error);
  }
};

// API call to add a beneficiary
const addBeneficiary = async (values, setSubmitting, getBankDetails, setAddbenesuccess, setFailedtoaddbene, setAccPresentPopup) => {
  try {
    const token = localStorage.getItem("jwtToken");
    const response = await axios.post(
      "https://api.ipaisa.co.in/myapp/auth/addBeneficiary",
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { statusCodeValue, body } = response.data;

    if (statusCodeValue == 406) {
      toast.error(body.message);
      setAccPresentPopup(true);  // Show failed popup

    } else if (statusCodeValue == 200) {
      toast.success("Beneficiary added successfully");
      await getBankDetails();
      setAddbenesuccess(true);  // Show success popup

    } else {
      toast.error("Unexpected response status");
      setFailedtoaddbene(true);  // Show failed popup
    }

    console.log("Beneficiary response:", response.data);
  } catch (error) {
    console.error("Error adding beneficiary:", error);
    toast.error("Failed to add beneficiary");
    setFailedtoaddbene(true);  // Show failed popup
  } finally {
    setSubmitting(false);
  }
};

// API call to verify bank details
const verifyBankDetails = async (accountNumber, ifsc) => {
  try {
    const token = localStorage.getItem("jwtToken");
    console.log("Verifying");
    const response = await axios.post(
      "https://api.ipaisa.co.in/myapp/auth/instantpay/bankverify",
      {
        accountNumber: accountNumber,
        bankIfsc: ifsc,
        longitude: "78.9628",
        latitude: "20.5936",

       
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        
      }
      
     
       
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error verifying bank details:", error);
    throw error;
  }
};

export { getBankDetails, addBeneficiary, verifyBankDetails };
