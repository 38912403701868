import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, TablePagination, IconButton } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.png'; // Adjust this path if needed

const useStyles = makeStyles(() => ({
    tableRow: {
        '& > *': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    evenRow: {
        backgroundColor: '#D0EFFF',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    }
}));

const NewTransactionalTable = ({ searchParams}) => {

    // const data = [...debitData, ...creditData]; // Merge debit and credit data

    const classes = useStyles();

    const rows = [
        { id: 1, transactionId: '123456', transactionDate: '2024-05-01 14:30', transactionAmount: 110.00, partnerId: 'P001', partnerName: 'John Doe', partnerEmail: 'john.doe@example.com', transactionStatus: 'Completed', remark: 'Some remark' },
        { id: 2, transactionId: '789012', transactionDate: '2024-05-02 09:15', transactionAmount: 165.00, partnerId: 'P002', partnerName: 'Jane Smith', partnerEmail: 'jane.smith@example.com', transactionStatus: 'Pending', remark: 'Another remark' },
    ];

    const filteredRows = rows.filter(row => {

        return (
   
            (!searchParams.transactionId || row.transactionId.includes(searchParams.transactionId)) &&
            (!searchParams.partnerId || row.partnerId.includes(searchParams.partnerId)) &&
            (!searchParams.partnerName || row.partnerName.toLowerCase().includes(searchParams.partnerName.toLowerCase()))
        );
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = (row) => {
        const doc = new jsPDF();
        doc.text(`Transaction Details`, 10, 10);
        doc.text(`Transaction ID: ${row.transactionId}`, 10, 20);
        doc.text(`Transaction Date: ${row.transactionDate}`, 10, 30);
        doc.text(`Transaction Amount: $${row.transactionAmount.toFixed(2)}`, 10, 40);
        doc.text(`Partner ID: ${row.partnerId}`, 10, 50);
        doc.text(`Partner Name: ${row.partnerName}`, 10, 60);
        doc.text(`Partner Email: ${row.partnerEmail}`, 10, 70);
        doc.text(`Transaction Status: ${row.transactionStatus}`, 10, 80);
        doc.text(`Remark: ${row.remark}`, 10, 90);
        doc.save(`transaction_${row.transactionId}.pdf`);
    };

    // const generatePDF = () => {
    //     const doc = new jsPDF();
    //     const logo = new Image();
    //     logo.src = iPaisaLogo;

    //     logo.onload = () => {
    //         doc.addImage(logo, 'PNG', 10, 10, 30, 30); // Adjust position and size as needed
    //         doc.setFont('helvetica', 'bold');
    //         doc.setFontSize(16);
    //         doc.text('Transaction Statement', 50, 20);
    //         doc.setFontSize(12);
    //         doc.text('24 Jun, 2024 - 24 Jul, 2024', 50, 30);

    //         const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
    //         const tableRows = [];

    //         data.forEach(row => {
    //             const rowData = [
    //                 new Date(row.date).toLocaleString(),
    //                 row.transactionType === 'DEBIT' 
    //                     ? `Paid to ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nPaid by ${row.senderNumber}`
    //                     : `Received from ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nCredited to ${row.senderNumber}`,
    //                 row.transactionType,
    //                 `${row.amount.toFixed(2)}`
    //             ];
    //             tableRows.push(rowData);
    //         });

    //         doc.autoTable({
    //             head: [tableColumn],
    //             body: tableRows,
    //             startY: 50,
    //             styles: {
    //                 fontSize: 10,
    //                 cellPadding: 3,
    //                 overflow: 'linebreak',
    //             },
    //             headStyles: {
    //                 fillColor: [22, 160, 133],
    //                 textColor: [255, 255, 255],
    //                 fontSize: 12,
    //             },
    //         });

    //         doc.save('Transaction_Statement.pdf');
    //     };
    // };
    
  return (
    <Grid sx={{marginTop:'3%'}}>
         <Grid>
            <Grid sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1%'}}>
                <Grid>
                <Typography sx={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'19px', color:'#343C6A'}}>History</Typography>
                </Grid>
            
                    {/* <Button sx={{textTransform:'none', backgroundColor:'#027BFE', color:'#FFFFFF', borderRadius:'10px', display:'flex', alignItems:'center', justifyContent:'center', fontFamily:'sans-serif', fontSize:'14px', width:'20%', gap:'5%'}}
                    onClick={generatePDF}
                    >
                    <DownloadIcon/>
                    Download PDF File</Button> */}
            </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ marginTop:'2%',maxWidth: '100%', overflowX: 'auto', borderRadius:'12px 12px 0px 0px'  }}>
                        <Table>
                            <TableHead sx={{backgroundColor: '#027BFE'}}>
                                <TableRow>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Sr. No.</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction ID</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction Date</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction Amount</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner ID</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner Name</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Partner Email</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Transaction Status</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Remark</TableCell>
                                    <TableCell sx={{color:'white' , textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={row.id} 
                                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{index + 1}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.transactionId}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.transactionDate}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.transactionAmount.toFixed(2)}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerId}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerName}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.partnerEmail}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.transactionStatus}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>{row.remark}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontSize: {xs:'9px',sm:'10px',md:'11px'} }}>
                                            <IconButton onClick={() => handleDownload(row)}>
                                                <DownloadIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
    </Grid>
  )
}

export default NewTransactionalTable;
