// PayoutForm.js
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Grid, Button, CircularProgress, MenuItem, Box } from '@mui/material';
import * as Yup from 'yup';

const PayoutSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    payeeaccountNumber: Yup.string().required('Account Number is required'),
    bankIfsc: Yup.string().required('Bank IFSC is required'),
    transferMode: Yup.string().required('Transfer Mode is required'),
    transferAmount: Yup.number().required('Transfer Amount is required').positive('Amount must be positive'),
    remarks: Yup.string(),
});

const PayoutForm = ({ onSubmit, bankDetails, isSubmitting,payoutModes }) => (
    <Formik
        initialValues={{
            name: '',
            payeeaccountNumber: '',
            bankIfsc: '',
            transferMode: '',
            transferAmount: '',
            remarks: '',
            purpose: 'REIMBURSEMENT',
        }}
        validationSchema={PayoutSchema}
        onSubmit={onSubmit}
    >
        {({ errors, touched, setFieldValue }) => (
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Field as={TextField} name="name" label="Name" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field
                            as={TextField}
                            label="Account Number"
                            name="payeeaccountNumber"
                            select
                            fullWidth
                            variant="outlined"
                            error={touched.payeeaccountNumber && Boolean(errors.payeeaccountNumber)}
                            helperText={touched.payeeaccountNumber && errors.payeeaccountNumber}
                            onChange={(event) => {
                                const selectedAccountNumber = event.target.value;
                                setFieldValue('payeeaccountNumber', selectedAccountNumber);

                                const selectedBankDetail = bankDetails.find(
                                    (bankDetail) => bankDetail.accountNumber === selectedAccountNumber
                                );

                                if (selectedBankDetail) {
                                    setFieldValue('bankIfsc', selectedBankDetail.ifsc);
                                    setFieldValue('name', selectedBankDetail.name);
                                }
                            }}
                        >
                            {bankDetails.map((bankDetail) => (
                                <MenuItem key={bankDetail.accountNumber} value={bankDetail.accountNumber}>
                                    {`${bankDetail.accountNumber} - ${bankDetail.name}`}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field as={TextField} name="bankIfsc" label="Bank IFSC" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field as={TextField} name="transferMode" label="Transfer Mode" select variant="outlined" fullWidth>
                            {payoutModes.map((mode) => (
                                <MenuItem key={mode.value} value={mode.value}>
                                    {mode.label}
                                </MenuItem>
                            ))}
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field as={TextField} name="transferAmount" label="Transfer Amount" variant="outlined" type="number" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field as={TextField} name="remarks" label="Remarks" variant="outlined" fullWidth />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '3%' }}>
                    <Box display="flex" justifyContent="end" gap={1}>
                        <Button sx={{ border: '1.5px solid #FF0000', color: '#FF0000', textTransform: "none" }}>Cancel</Button>
                        <Button type="submit" sx={{ backgroundColor: "#027BFE", color: "white", textTransform: "none" }} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Pay'}
                        </Button>
                    </Box>
                </Grid>
            </Form>
        )}
    </Formik>
);

export default PayoutForm;
