import { Grid, Container, Paper } from '@material-ui/core';
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { PrintIcon } from '../../res/NewWebIcons';
import html2pdf from 'html2pdf.js'; // Ensure html2pdf is imported if used here
import logo from "../Assets/login/iPaisaLogo.png";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';


const PayoutReportInvoice = ({ data }) => {
    const userID = localStorage.getItem('userId');
    const userName = localStorage.getItem('username');

    if (!data) return null;

    console.log('Generating PDF for transaction:', data.txnReferenceId);

    return (
        <Container 
            style={{ 
                padding: '1rem', 
                width: '100%', 
                maxWidth: '1000px', 
                boxSizing: 'border-box',
                backgroundColor: '#f0f0f0', // Set the background color here
                minHeight: '90vh', // Ensure the background color covers the full height
            }}
        >
            <Paper 
                elevation={3}
                style={{
                    paddingTop:'5%',
                    padding: '2rem',
                    backgroundColor: '#fff8ee', // Set the inner content background as white to contrast with outer container
                    boxSizing: 'border-box',
                    color:'#244d8a' ,               
                }}
            >
                <Grid style={{display:'flex', justifyContent:'center'}}>
                    <Typography style={{fontSize:'18px', fontWeight:"bold", marginTop:'-2%'}}>Payout Receipt </Typography>
                </Grid>
                <Grid style={{marginTop:'1%'}}>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid style={{display:'flex', alignItems:'center', gap:'6%'}}>
                            <img src={logo} alt="iPaisa Logo" style={{ height: 65, width: 65, backgroundColor: 'transparent', borderRadius: '50%' }} />
                            <Typography style={{ fontWeight: 'bold', fontSize: '28px' }}>iPaisa</Typography>
                    </Grid>                    
                    <Typography style={{ fontWeight:"bold", fontSize:"20px" }}>EDSOM FINTECH PRIVATE LIMITED</Typography>
                    </Grid>
                    <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid style={{ marginTop: '1%' }}>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                                {new Date(data.timestamp).toLocaleDateString() || 'N/A'}
                            </Typography>
                            <Typography style={{ fontSize: '14px', fontWeight: 'bold', textAlign:"start" }}>
                            {new Date(data.timestamp).toLocaleTimeString() || 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography style={{ fontSize: '14px', textAlign:"end" }}>
                                201, BANER ROAD, PUNE, MAHARASHTRA - 411045
                            </Typography>
                            <Typography style={{ fontSize: '14px', textAlign:"end" }}>
                                edsomfintech@gmail.com
                            </Typography>
                        </Grid>
                    </Grid>
                
                <Grid style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:"3%"}}>
                    <Grid style={{textAlign:'start'}}>
                        <Typography style={{fontSize:"16px", fontWeight:'bold' }}>From :</Typography>
                        <Typography style={{fontSize:'18px'}}>{userName}</Typography>
                    </Grid>
                    <Grid style={{textAlign:'end'}}>
                        <Typography style={{fontSize:"16px", fontWeight:'bold' }}>To :</Typography>
                        <Typography style={{fontSize:'18px'}}>{data.payeeName}</Typography>
                    </Grid>
                </Grid>

                    <TableContainer style={{ marginTop: '5%' }}>
                        <Table aria-label="transaction table" style={{ tableLayout: 'fixed', width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'10px', color:'#244d8a' }}>Txn ID</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '15%', fontSize:'10px', textAlign:'center', color:'#244d8a' }}>UTR No.</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '15%', fontSize:'10px', paddingLeft:'5%',textAlign:'start', color:'#244d8a'}}>Account No.</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '12%', fontSize:'10px', paddingLeft:'5%', color:'#244d8a'}}>Account Name</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '10%', fontSize:'10px', paddingLeft:'5%', color:'#244d8a'}}>Amount</TableCell>
                                    <TableCell style={{ fontWeight:"bold", width: '18%', fontSize:'10px', paddingLeft:'7%', color:'#244d8a'}}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{fontSize:'10px', color:'#244d8a'}}>{data.txnReferenceId || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'10px', color:'#244d8a'}}>{data.externalRef || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'10px', paddingLeft:'5%', color:'#244d8a'}}>{data.payeeAccount || 'N/A'}</TableCell>
                                    <TableCell style={{fontSize:'10px', paddingLeft:'5%', color:'#244d8a'}}>{data.payeeName || 'N/A'}</TableCell>                                                          
                                    <TableCell style={{fontSize:'10px', paddingLeft:'5%', color:'#244d8a'}}>{data.txnValue || 'N/A'}</TableCell>                                  
                                    <TableCell style={{fontSize:'10px', paddingLeft:'7%', textAlign: 'start', color:'#244d8a'}}>
                                        {data.status === 'Transaction Successful' ? 'Successful'
                                            : data.status === 'Transaction Pending' ? 'Pending'
                                                : data.status === 'REFUND' ? 'Refund'
                                                    : data.status === 'Transaction Failed' ? 'Failed'
                                                        : data.status || 'N/A'} {/* Default value if no match */}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid style={{ marginTop:'4%', display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                        <Grid>
                            <Typography style={{ fontWeight:'bold', fontSize:'20px' }}>
                                Thank you for transacting at iPaisa!
                            </Typography>
                            <Typography color='textSecondary' style={{fontSize:'12px', color:'#244d8a'}}>
                                This is a system generated receipt hence does not require any signature.
                            </Typography>
                            <Typography color='textSecondary' style={{fontSize:'12px', color:'#244d8a'}}>
                                Queries? Write to care@ipaisa.co.in
                            </Typography>
                        </Grid>
                        <Grid>
                            <Divider/>
                            <Grid>
                                <Typography style={{ fontWeight:'bold' }}>Total Amount : {data.txnValue}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default PayoutReportInvoice;
