import React from "react";
import { useLocation } from "react-router-dom";
import MiniDrawer from "./components/Dashboard/Navbar";
import ResponsiveDrawer from "./components/Dashboard/Navbar2";
import { MobileView, BrowserView, TabletView } from "react-device-detect";

const Layout = ({ children }) => {
  const location = useLocation();

  // List of routes where the MiniDrawer should not be rendered
  const excludedRoutes = [
    "/login",
    "/",
    "/career",
    "/aboutus",
    "/contactus",
    "/blogs",
    "/services",
    '/gallery',
    '/ourteam',
    "/signin",
    "/privacypolicy",
    "/faq",
    "/termsandconditions",
    "/refundandcancel",
    "/deeplinkpage/**",
    "/dashboard2",
    "*" // Match any other route not explicitly listed
  ];

  const isExcludedRoute = excludedRoutes.includes(location.pathname.toLowerCase());
  const isAuthenticated = !!localStorage.getItem("jwtToken");

  return (
    <React.Fragment>
      <MobileView>
        <div>
          {/* Ternary operator to conditionally render the ResponsiveDrawer */}
          {!isExcludedRoute && isAuthenticated ? <MiniDrawer /> : null}
          {/* Main content area */}
          <div style={{ height: "100%", width: "100%" }}>
            {/* Render the child components */}
            <div>{children}</div>
          </div>
        </div>
      </MobileView>

      <TabletView>
        <div style={{ display: "flex" }}>
          {/* Conditionally render the MiniDrawer or ResponsiveDrawer for tablet view */}
          {!isExcludedRoute && isAuthenticated ? <MiniDrawer /> : null}
          {/* Main content area */}
          <div style={{ height: "100%", width: "100%" }}>
            {/* Render the child components */}
            <div>{children}</div>
          </div>
        </div>
      </TabletView>

      <BrowserView>
        {/* Desktop view layout */}
        <div style={{ display: "flex" }}>
          {/* Always render MiniDrawer for desktop view */}
          {!isExcludedRoute && isAuthenticated ? <MiniDrawer /> : null}
          {/* Main content area */}
          <div style={{ height: "100%", width: "100%" }}>
            {/* Render the child components */}
            <div>{children}</div>
          </div>
        </div>
      </BrowserView>
    </React.Fragment>
  );
};

export default Layout;
