// Ensure you have @mui/material installed
// npm install @mui/material @emotion/react @emotion/styled

import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material"; // Changed to @mui/material
import axios from "axios"; // Import axios for making API requests
// Import standard Material-UI icons for debugging purposes
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { BbpsBIcon, ComplaintFinishIcon } from "../../res/NewWebIcons";
import logo from "../Assets/login/iPaisaLogo.png";
import { FailedPopIcon1 } from "../../res/icons";
import GreenCheckmark from "../Assets/SuccessandFailedGif/GreenCheckmark.gif";
import { NewBBPSIcon, PartnerArrowIcon } from '../../res/NewWebIcons';




// ComplaintInvoice Component
const ComplaintInvoice = ({ handleClose, data }) => {
    const { complaintDateTime, typeOfComplaint, txnReferenceId, complaintId, complaintStatus } = data;

    const successIconStyle = {
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 70,
        height: 65,
    };

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                style: {
                    width: '600px',
                    padding: '1rem',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                }
            }}
        >
            <Grid container>
                {/* Icon Section */}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={logo} alt="iPaisa Logo" style={{ height: 54, width: 54, backgroundColor: 'transparent', borderRadius: '50%' }} />
                    <Box sx={successIconStyle}>
                        <img src={GreenCheckmark} style={{ height: '100%', width: '100%', borderRadius: '50%' }} alt="Success Gif" />
                    </Box>
                    <BbpsBIcon />
                </Grid>

                {/* Title Section */}
                <Grid container justifyContent="center" style={{ marginTop: '4%' }}>
                    <Typography style={{ fontWeight: 'bold', fontSize: '22px', color: 'green' }}>
                        Successful Complaint Registration
                    </Typography>
                </Grid>

                {/* Details Section */}
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '70%', marginTop: '6%' }}>
                    {/* Left Side (Labels) */}
                    <Grid container direction="column" spacing={1} xs={6}>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint Date & Time</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Type of Complaint</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Transaction Ref ID</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint ID</Typography>
                        <Typography color="textSecondary" style={{ fontSize: '14px' }}>Complaint Status</Typography>
                    </Grid>

                    {/* Right Side (Values) */}
                    <Grid container direction="column" spacing={1} xs={6}>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{complaintDateTime}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{typeOfComplaint}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{txnReferenceId}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{complaintId}</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{complaintStatus}</Typography>
                    </Grid>
                </Grid>

                {/* Close Button */}
                <Grid container justifyContent="flex-end" style={{ marginTop: '2rem' }}>
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Close
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

// ComplaintRegisFail Component with Custom Styling
const ComplaintRegisFail = ({ handleClose, data }) => {
  // Extracting values from the data prop
  const responseCode = data?.responseCode || "N/A";
  const responseReason = data?.responseReason || "N/A";
  const txnReferenceId = data?.referenceId || "N/A";
  const status = "Failure"; // Fixed value for failure component
  const currentDateTime = new Date().toLocaleString(); // Getting current date and time

  // Styles for failure icon
  const failureIconStyle = {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 70,
    height: 65,
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "600px",
          padding: "1rem",
        },
      }}
    >
      <Grid container>
        {/* Icon Section */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="iPaisa Logo"
            style={{
              height: 54,
              width: 54,
              backgroundColor: "transparent",
              borderRadius: "50%",
            }}
          />
          <Box sx={failureIconStyle}>
            <FailedPopIcon1 />
          </Box>
          <BbpsBIcon />
        </Grid>

        {/* Title Section */}
        <Grid container justifyContent="center" style={{ marginTop: "4%" }}>
          <Typography
            style={{ fontWeight: "bold", fontSize: "22px", color: "red" }}
          >
            Complaint Registration Failed
          </Typography>
        </Grid>

        {/* Details Section */}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "100%",
            marginTop: "6%",
          }}
        >
          {/* Left Side (Labels) */}
          <Grid item container direction="column" spacing={1} xs={6}>
            <Typography color="textSecondary" style={{ fontSize: "14px" }}>
              Complaint Date & Time
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "14px" }}>
              Response Reason
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "14px" }}>
              Transaction Ref ID
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "14px" }}>
              Response Code
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: "14px" }}>
              Complaint Status
            </Typography>
          </Grid>

          {/* Right Side (Values) */}
          <Grid item container direction="column" spacing={1} xs={6}>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              {currentDateTime}
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              {responseReason}
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              {txnReferenceId}
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              {responseCode}
            </Typography>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              {status}
            </Typography>
          </Grid>
        </Grid>

        {/* Close Button */}
        <Grid container justifyContent="flex-end" style={{ marginTop: "2rem" }}>
          <Button
            onClick={handleClose}
            sx={{
              color: "white",
              backgroundColor: "#027BFE",
              textTransform: "none",
              width: "18%",
              "&:hover": {
                backgroundColor: "#0256a3",
              },
            }}
            variant="contained"
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

const ComplaintRegistration = () => {
  const [typeOfComplaint, setTypeOfComplaint] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [txnReferenceId, setTxnReferenceId] = useState("");
  const [complaintReason, setComplaintReason] = useState("");
  const [complaintDescription, setComplaintDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Removed successMessage as it's not used
  const [complaintInvoice, setComplaintInvoice] = useState(false);
  const [complaintFail, setComplaintFail] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [failData, setFailData] = useState({}); // State for failure data

  // Complaint types array
  const typeOfComplaints = ["Transaction", "Electricity"];
  const complaintTypes = ["Amount deducted multiple times"];

  // Complaint reasons array
  const complaintReasons = [
    "Wrong Bill Amount",
    "Service Not Working",
    "Payment Failed",
    "Others",
  ];

  const handleClose = () => {
    setComplaintInvoice(false);
    setComplaintFail(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    // setSuccessMessage(null); // Not used

    try {
      const token = localStorage.getItem("jwtToken");

      const response = await axios.post(
        "http://192.168.31.163:5001/myapp/auth/bbps/complaintRegister",
        {
          typeOfComplaint,
          complaintType,
          mobileNumber,
          txnReferenceId, // Reference ID sent in payload
          complaintReason,
          complaintDescription,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          timeout: 300000, // Set timeout to 5 minutes (300,000 milliseconds)
        }
      );

      console.log("API Response:", response); // Debugging

      // Check if HTTP status is 200
      if (response.status === 200) {
        const responseData = response.data.response;
        const nestedResponse = responseData.response;

        // Check if respCode and status indicate success and nested response exists
        if (
          responseData.respCode === "1" &&
          responseData.status === "SUCCESS" &&
          nestedResponse?.txnStatusComplainResp
        ) {
          const txnStatus = nestedResponse.txnStatusComplainResp;

          setInvoiceData({
            complaintDateTime: new Date().toLocaleString(), // Current date and time
            typeOfComplaint,
            txnReferenceId: txnStatus.referenceId || "N/A", // Use referenceId from response
            complaintId: txnStatus.complaintId || "N/A",
            complaintStatus: txnStatus.complaintStatus || "Assigned",
            assignedTo: txnStatus.assigned || "Unknown",
            responseCode: txnStatus.responseCode || "000",
            responseReason: txnStatus.responseReason || "SUCCESS",
          });

          setComplaintInvoice(true); // Show the success invoice popup
        } else {
          // If the response indicates failure, handle the failure case
          setFailData({
            referenceId: txnReferenceId, // Use txnReferenceId from form input in case of error
            responseCode: responseData.respCode || "N/A",
            responseReason:
              responseData.status || "Failed to register complaint.",
          });

          setComplaintFail(true); // Show failure popup in case of error
        }
      } else {
        // If HTTP status is not 200, treat as failure
        setFailData({
          referenceId: txnReferenceId, // Use txnReferenceId in case of error
          responseCode: response.status,
          responseReason: "Failed to register complaint: " + response.statusText,
        });

        setComplaintFail(true); // Show failure popup in case of error
      }
    } catch (err) {
      console.error("Error during complaint registration:", err); // Debugging

      // If there's an error, handle it and use txnReferenceId from the payload
      setFailData({
        referenceId: txnReferenceId, // Use txnReferenceId in case of error
        responseCode: "N/A",
        responseReason:
          "Failed to register complaint: " +
          (err.response?.data?.message || err.message || "Please try again."),
      });

      setComplaintFail(true); // Show failure popup in case of error

      if (err.response) {
        setError(
          `Failed to register complaint: ${err.response.data.message || "Please try again."}`
        );
      } else if (err.request) {
        setError("No response received from server. Please check your connection.");
      } else {
        setError("Error: " + err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setTypeOfComplaint("");
    setComplaintType("");
    setMobileNumber("");
    setTxnReferenceId("");
    setComplaintReason("");
    setComplaintDescription("");
    setError(null);
    // setSuccessMessage(null); // Not used
  };

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid>
        {/* Header Section */}
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1%",
            justifyContent: "start",
          }}
        >
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "12px" }}
          >
            Complaint Management
          </Typography>
          <PartnerArrowIcon />
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "12px" }}
          >
            Complaint Registration
          </Typography>
        </Grid>

        {/* Title and Icon */}
        <Grid
          style={{
            marginTop: "1%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontFamily: "sans-serif",
              fontSize: "19px",
              color: "#343C6A",
            }}
          >
            Complaint Registration
          </Typography>
          <Typography>
            <NewBBPSIcon />
          </Typography>
        </Grid>

        {/* Complaint Form */}
        <Paper elevation={2} style={{ padding: "2rem", marginTop: "16px" }}>
          <Grid container spacing={2} style={{ marginTop: "2%" }}>
            {/* Type of Complaint */}
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Type of Complaint"
                value={typeOfComplaint}
                onChange={(e) => setTypeOfComplaint(e.target.value)}
                fullWidth
                variant="outlined"
              >
                {typeOfComplaints.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Mobile Number */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Enter Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                fullWidth
                variant="outlined"
                type="tel"
                inputProps={{ maxLength: 15 }}
              />
            </Grid>

            {/* Complaint Type */}
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Complaints Type"
                value={complaintType}
                onChange={(e) => setComplaintType(e.target.value)}
                fullWidth
                variant="outlined"
              >
                {complaintTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Transaction ID */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Transaction ID"
                value={txnReferenceId}
                onChange={(e) => setTxnReferenceId(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            {/* Reason for Complaint */}
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Reason for Complaint"
                value={complaintReason}
                onChange={(e) => setComplaintReason(e.target.value)}
                fullWidth
                variant="outlined"
              >
                {complaintReasons.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Complaint Description */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Complaint Description"
                value={complaintDescription}
                onChange={(e) => setComplaintDescription(e.target.value)}
                fullWidth
                variant="outlined"
                multiline
                rows={4}
              />
            </Grid>

            {/* Submit and Reset Buttons */}
            <Grid
              container
              item
              justifyContent="flex-end"
              spacing={2}
              style={{ marginTop: "2%" }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Error Message */}
          {error && (
            <Typography color="error" sx={{ marginTop: "16px" }}>
              {typeof error === "object" ? error.message : error}
            </Typography>
          )}
          {/* Remove successMessage as it's not used */}
          {/* {successMessage && (
            <Typography color="primary">{successMessage}</Typography>
          )} */}
        </Paper>
      </Grid>

      {/* Complaint Invoice Popup */}
      {complaintInvoice && (
        <ComplaintInvoice data={invoiceData} handleClose={handleClose} />
      )}

      {/* Complaint Registration Failure Popup */}
      {complaintFail && (
        <ComplaintRegisFail data={failData} handleClose={handleClose} />
      )}
    </Container>
  );
};

export default ComplaintRegistration;
