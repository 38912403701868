import React, { useState, useRef } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify'; // Ensure you have react-toastify for toasting messages
import SetPassword from './SetIPMpin'; // Import your SetPassword component

const ChangeIPin = ({ onMpinValid, handleDialogClose }) => {
  const [pins, setPins] = useState(['', '', '', '']);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSetPasswordDialogOpen, setSetPasswordDialogOpen] = useState(false); // State for the SetPassword dialog
  const refs = useRef(Array(4).fill(null)); // Create refs for input boxes

  const handleChange = (value, index) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newPins = [...pins];
      newPins[index] = value;
      setPins(newPins);

      // Move to the next input box if the value is entered
      if (value && index < 3) {
        refs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index) => {
    const newPins = [...pins];
    newPins[index] = '';
    setPins(newPins);

    // Move to the previous input box if the current box is empty and not the first box
    if (index > 0 && !pins[index]) {
      refs.current[index - 1].focus();
    }
  };

  const handleSubmit = async () => {
    const mpin = pins.join(''); // Join the pins into a single string
    const token = localStorage.getItem('jwtToken');

    setLoading(true);

    try {
      const response = await axios.post(
        'https://api.ipaisa.co.in/myapp/auth/validateTxnMpin',
        { mpin },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast(response.data.body.message);

      if (response.data.statusCodeValue === 200 && response.data.body.message === 'MPIN is valid') {
        setIsVerified(true);
        setPins(['', '', '', '']); // Reset the input fields
        setSetPasswordDialogOpen(true); // Open the SetPassword dialog
      } else if (response.data.statusCodeValue === 401) {
        setErrorMessage('Invalid MPIN');
        // You may want to handle the ForgotPin component here
      } else {
        setErrorMessage(response.data.body.message);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404 && error.response.data.body.message === 'MPIN not set for user') {
          toast.error(error.response.data.body.message);
          setErrorMessage('Please set your IPIN first!');
        } else if (error.response.status === 401) {
          setErrorMessage('Invalid MPIN');
          // You may want to handle the ForgotPin component here
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = () => {
    const newPin = pins.join('');
    if (newPin.length === 4) {
      console.log('Verifying MPIN:', newPin);
      handleSubmit(); // Call the submit function to verify the MPIN
    } else {
      alert('Please enter a valid 4-digit MPIN.');
    }
  };

  const renderPasswordFields = (password, handleChange, handleBackspace, refs) => {
    return (
      <Grid container spacing={2}>
        {password.map((char, index) => (
          <Grid item key={index}>
            <TextField
              inputProps={{
                maxLength: 1,
                style: {
                  width: '48px',
                  height: '48px',
                  textAlign: 'center',
                  borderRadius: '6px',
                  border: '1px solid #ccc',
                },
              }}
              value={char}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  handleBackspace(index);
                }
              }}
              inputRef={(el) => (refs.current[index] = el)}
              type="password"
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: '10%' }}>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            textAlign: 'center',
            marginTop: 8,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Change MPIN
          </Typography>
          <Box mt={3}>
            <Grid container justifyContent="flex-start" alignItems="center">
              {/* Render password fields */}
              <Grid item>
                {renderPasswordFields(pins, handleChange, handleBackspace, refs)}
              </Grid>
              {/* Align Verify button next to the input fields */}
              <Grid item sx={{ marginLeft: '10%' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerify}
                  disabled={loading} // Disable the button when loading
                  sx={{ marginLeft: 2 }} // Add some margin to the left for spacing
                >
                  {loading ? 'Verifying...' : 'Verify'} {/* Change button text while loading */}
                </Button>
              </Grid>
            </Grid>
          </Box>
          {isVerified && (
            <Typography variant="body1" color="success.main" mt={3}>
              MPIN Verified Successfully!
            </Typography>
          )}
          {errorMessage && (
            <Typography variant="body1" color="error.main" mt={3}>
              {errorMessage} {/* Display error messages */}
            </Typography>
          )}
        </Paper>
      </Container>
      {/* SetPassword Dialog */}
      <SetPassword 
        open={isSetPasswordDialogOpen} 
        onClose={() => setSetPasswordDialogOpen(false)} 
      />
    </>
  );
};

export default ChangeIPin;
