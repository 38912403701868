import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Typography, Grid, CircularProgress,
    Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.png'; // Adjust this path if needed
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import jsPDF from 'jspdf';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles((theme) => ({
    evenRow: {
        backgroundColor: '#C1E0FB',
    },
    oddRow: {
        backgroundColor: '#FFFFFF',
    },
    PayInText: {
        color: 'green',
    },
}));

const PayIn = () => {
    const classes = useStyles();
    const [payInData, setPayInData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPayInData = async () => {
            const jwtToken = localStorage.getItem('jwtToken');
            try {
                const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/easTxnDetails', {
                    headers: { Authorization: `Bearer ${jwtToken}` }
                });
                const sortedData = (response.data.body || []).sort((a, b) => new Date(b.addedon) - new Date(a.addedon));
                setPayInData(sortedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching PayIn data:', error);
                setLoading(false);
            }
        };

        fetchPayInData();
    }, []);

    const formatDate = (dateTimeString) => {
        if (!dateTimeString) return ''; // Return an empty string if dateTimeString is null or undefined

        const [datePart, timePart] = dateTimeString.split('T');
        if (!datePart || !timePart) return dateTimeString; // Return the original string if it can't be split

        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}-${month}-${year}`;

        return `${formattedDate} ${timePart}`;
    };

    const username = localStorage.getItem("username");
    const generatePDF = () => {
        if (!payInData || payInData.length === 0) {
            alert('No transaction data available to generate PDF.');
            return;
        }

        const doc = new jsPDF();
        const logo = new Image();
        logo.src = iPaisaLogo;

        logo.onload = () => {
            doc.addImage(logo, 'PNG', 10, 10, 30, 30); // Adjust position and size as needed
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(16);
            doc.text('Transaction Statement', 50, 20);
            doc.setFontSize(12);
            doc.text('24 Jun, 2024 - 24 Jul, 2024', 50, 30);

            const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
            const tableRows = [];

            payInData.forEach(row => {
                const rowData = [
                    formatDate(row.addedon),
                    row.type === 'Pay-Out'
                        ? `Paid to : ${row.reciverName}\nTransaction ID : ${row.txnId}\nUTR No. : ${row.utrNo}\nPaid by : ${row.senderName}\nAccount Number : ${row.accountNumber}\nTransfer Amount : ${row.amount}\nWallet Opening Balance : ${Number(row.walletOpeningBalance).toFixed(2)}\nWallet Closing Balance : ${Number(row.walletClosingBalance).toFixed(2)}\nStatus : ${row.status}`
                        : `Received from ${row.firstname}\nTransaction ID ${row.txnid}\nUTR No. ${row.easepayid}\nCredited to ${username}`,
                    row.type,
                    `${row.amount}`
                ];
                tableRows.push(rowData);
            });

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 50,
                styles: {
                    fontSize: 10,
                    cellPadding: 3,
                    overflow: 'linebreak',
                },
                headStyles: {
                    fillColor: [22, 160, 133],
                    textColor: [255, 255, 255],
                    fontSize: 12,
                },
            });

            doc.save('Transaction_Statement.pdf');
        };
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container spacing={3} justifyContent="center" style={{ padding: '20px', marginTop: '7%' }}>

            <Grid item xs={12}>
                <Typography variant="h4" align="start" gutterBottom style={{ fontWeight: 'bold' }}>
                    PayIn History
                </Typography>
                <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                    <Button
                        onClick={generatePDF}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'none' }}
                        startIcon={<DownloadIcon />}
                    >
                        Download PDF File
                    </Button>
                </Grid>
                <Paper elevation={3} style={{ padding: '20px' }}>

                    <TableContainer>
                        <Table>
                            <TableHead style={{ backgroundColor: '#027BFE', color: 'white' }}>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }} align="center">Sr. No.</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Type</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Txn ID</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">UTR No</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Status</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Date</TableCell>
                                    <TableCell style={{ color: 'white' }} align="center">Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {payInData && payInData.length > 0 ? (
                                    payInData.map((txn, index) => (
                                        <TableRow key={txn.id} className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                                            <TableCell align="center">{index + 1}</TableCell>
                                            <Grid style={{display:'flex', justifyContent:'center', alignItems:'center'}} >
                                                <TableCell align="center" style={{color:'green', fontWeight:'bold', fontSize:'17px'}}>Pay-In</TableCell>
                                                <CallReceivedIcon style={{color:'green'}}/>
                                            </Grid>
                                            <TableCell align="center">{txn.txnid}</TableCell>
                                            <TableCell align="center">{txn.easepayid}</TableCell>
                                            <TableCell align="center">{txn.status}</TableCell>
                                            <TableCell align="center">{formatDate(txn.addedon)}</TableCell>
                                            <TableCell align="center">{txn.amount}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No transactions found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PayIn;
