import PartnersArrow_SVG from './PartnersArrowIcon.svg'
import AddUser_SVG from './AddUserIcon.svg'
import Filter_SVG from './filterIcon.svg'
import Download_SVG from './DownloadIcon.svg'
import Plus_SVG from './PlusIcon.svg'
import ViewDetails_SVG from './ViewDetailIcon.svg'
import DeleteUser_SVG from './DeleteUserIcon.svg'
import Print_SVG from './PrintIcon.svg'
import BbpsB_SVG from './bbpsBIcon.svg'
import ComplaintFinish_SVG from './ComplaintFinishIcon.svg'
import NewBBPS_SVG from './NewBBPSIcon.svg'
import Clock_SVG from './clockIcon.svg'
import Note_SVG from './NoteIcon.svg'


export const PartnerArrowIcon = (hg, wd) => {
    return <img src={PartnersArrow_SVG} alt='PartnersArrow_SVG' style={{height:'10px'}}/>
}

export const NoteIcon = (hg, wd) => {
    return <img src={Note_SVG} alt='Note_SVG' style={{height:'16px'}}/>
}

export const ClockIcon = (hg, wd) => {
    return <img src={Clock_SVG} alt='Clock_SVG' style={{height:'16px'}}/>
}
export const AddUserIcon = (hg, wd) => {
    return <img src={AddUser_SVG} alt='AddUser_SVG' style={{height:'25px'}}/>
}
export const FilterIcon = (hg, wd) => {
    return <img src={Filter_SVG} alt='Filter_SVG' style={{height:'30px'}}/>
}
export const DownloadIcon = (hg, wd) => {
    return <img src={Download_SVG} alt='Download_SVG' style={{height:'20px'}}/>
}
export const PlusIcon = (hg, wd) => {
    return <img src={Plus_SVG} alt='Plus_SVG' style={{height:'18px'}}/>
}
export const ViewDetailsIcon = (hg, wd) => {
    return <img src={ViewDetails_SVG} alt='ViewDetails_SVG' style={{height:'20px'}}/>
}
export const DeleteUserIcon = (hg, wd) => {
    return <img src={DeleteUser_SVG} alt='DeleteUser_SVG' style={{height:'20px'}}/>
}
export const PrintIcon = (hg, wd) => {
    return <img src={Print_SVG} alt='Print_SVG' style={{height:'25px'}}/>
}
export const BbpsBIcon = (hg, wd) => {
    return <img src={BbpsB_SVG} alt='BBpsB_SVG' style={{height:'70px'}}/>
}
export const ComplaintFinishIcon = (hg, wd) => {
    return <img src={ComplaintFinish_SVG} alt='ComplaintFinish_SVG' style={{height:'50px'}}/>
}
export const NewBBPSIcon = (hg, wd) => {
    return <img src={NewBBPS_SVG} alt='NewBBPS_SVG' style={{height:'30px'}}/>
}
