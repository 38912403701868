import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Pagination,
  InputBase,
  IconButton,
  Box,
} from '@mui/material';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik, Form } from 'formik';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DownloadIcon, PartnerArrowIcon } from '../../res/NewWebIcons';
import Papa from 'papaparse'; // Import PapaParse
import iPaisaLogo from '../Assets/iPaisaLogo/iPaisaLogo.png';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import DownloadIcon1 from "@mui/icons-material/Download";
import FTRReportsInvoice from './FTRReportsInvoice';


const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: '#D0EFFF',
  },
  oddRow: {
    backgroundColor: '#FFFFFF',
  },
}));

const NewFundTReport = () => {
  const [debitData, setDebitData] = useState([]);
  const [creditData, setCreditData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const classes = useStyles();

  const validationSchema = Yup.object({
    fromDate: Yup.date().required('Required'),
    toDate: Yup.date().required('Required'),
  });

  const fetchDebitData = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/wtransReportDebt', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching debit data:', error);
      setError(error.message);
      return [];
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad
    const year = date.getFullYear(); // Get year
    return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
  };
  const fetchCreditData = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get('https://api.ipaisa.co.in/myapp/auth/wtransReportCred', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching credit data:', error);
      setError(error.message);
      return [];
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const debit = await fetchDebitData();
      const credit = await fetchCreditData();

      // Sort by date in descending order
      debit.sort((a, b) => new Date(b.date) - new Date(a.date));
      credit.sort((a, b) => new Date(b.date) - new Date(a.date));
      setDebitData(debit);
      setCreditData(credit);
      setFilteredData([...debit, ...credit]); // Combine sorted arrays
      setLoading(false);
    };

    loadData();
  }, []);

  const handleSearch = (values) => {
    const { fromDate, toDate } = values;
    const from = new Date(fromDate);
    const to = new Date(toDate);
    to.setHours(23, 59, 59, 999);

    const filteredDebitData = debitData.filter((item) =>
      new Date(item.date) >= from && new Date(item.date) <= to
    );
    const filteredCreditData = creditData.filter((item) =>
      new Date(item.date) >= from && new Date(item.date) <= to
    );

    setFilteredData([...filteredDebitData, ...filteredCreditData]);
    setPage(0);
  };

  // New function to handle clear button
  const handleClear = (resetForm) => {
    // Reset form values to empty
    resetForm();
    // Reset the filtered data to the full dataset
    setFilteredData([...debitData, ...creditData]);
  };

  const handleDownloadPDF = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.senderId}`);

    if (!receiptElement) {
      console.error('Receipt element not found:', `receipt-${data.senderId}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `Fund Transfer Receipt-${data.senderId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', orientation: 'landscape', format: 'a4', margin: 0.5 }
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error('Error generating PDF: ', error);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.addImage(iPaisaLogo, 'PNG', 10, 10, 30, 30);
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(16);
    doc.text('Fund Transfer Report', 50, 20);

    const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
    const tableRows = filteredData.map(row => [
      new Date(row.date).toLocaleString(),
      row.transactionType === 'DEBIT'
        ? `Paid to ${row.senderName}\nTransaction ID ${row.tranxid}\nRemark. ${row.remark}\nPaid by ${row.senderNumber}`
        : `Received from ${row.senderName}\nTransaction ID ${row.tranxid}\nRemark. ${row.remark}\nCredited to ${row.senderNumber}`,
      row.transactionType,
      `${row.amount.toFixed(2)}`,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontSize: 12,
      },
    });

    doc.save('Transaction_Statement.pdf');
  };

  const generateCSV = () => {
    const csvData = uniqueData.map((row, index) => ({
      'Sr. No.': index + 1,
      'Receiver Name': row.senderName,
      'Receiver ID': row.senderId,
      'Transaction ID': row.tranxid,
      'Amount': row.amount.toFixed(2),
      'Date': new Date(row.date).toLocaleString(),
      'Remark': row.remark,
      'Sender Number': row.senderNumber,
      'Transaction Type': row.transactionType,
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', 'Transaction_Statement.csv');
    a.style.visibility = 'hidden';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const uniqueData = filteredData.filter((item, index, self) =>
  //   index === self.findIndex((t) => t.tranxid === item.tranxid)
  // );

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchButtonClick = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredUniqueData = uniqueData.filter((row) =>
      row.senderName.toLowerCase().includes(lowerCaseQuery) ||
      row.senderId.toLowerCase().includes(lowerCaseQuery) ||
      row.tranxid.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredData(filteredUniqueData);
    setPage(0);
  };
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const debit = await fetchDebitData();
      const credit = await fetchCreditData();

      // Combine and sort both debit and credit data by date in descending order
      const combinedData = [...debit, ...credit].sort((a, b) => new Date(b.date) - new Date(a.date));

      setDebitData(debit);
      setCreditData(credit);
      setFilteredData(combinedData); // Use combined and sorted data
      setLoading(false);
    };

    loadData();
  }, []);

  // Ensure the uniqueData is sorted by date in descending order
  const uniqueData = filteredData
    .filter((item, index, self) =>
      index === self.findIndex((t) => t.tranxid === item.tranxid)
    )
    .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort in descending order by date

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
      <Grid style={{ display: 'flex', alignItems: 'center', gap: '1%', justifyContent: 'start' }}>
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Report</Typography>
        <PartnerArrowIcon />
        <Typography color='textSecondary' style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>Fund Transfer Report</Typography>
      </Grid>
      <Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1%' }}>
          <Grid>
            <Typography style={{ fontWeight: 'bold', fontFamily: 'sans-serif', fontSize: '19px', color: '#343C6A' }}>Fund Transfer Report</Typography>
          </Grid>
          <Grid style={{ display: 'flex', gap: '1rem' }}>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#027BFE',
                color: '#FFFFFF',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 16px',
                fontFamily: 'sans-serif',
                fontSize: '14px',
                whiteSpace: 'nowrap', // Ensure text stays in one line
                gap: '5%',
                height: '40px',
              }}
              onClick={generateCSV}
            >
              <DownloadIcon />
              Download CSV File
            </Button>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#027BFE',
                color: '#FFFFFF',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 16px',
                fontFamily: 'sans-serif',
                fontSize: '14px',
                whiteSpace: 'nowrap', // Ensure text stays in one line
                gap: '5%',
                height: '40px',
              }}
              onClick={generatePDF}
            >
              <DownloadIcon />
              Download PDF File
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Paper elevation={2} sx={{ p: 3, borderRadius: 2, marginTop: '2%' }}>
        <Formik
          initialValues={{ fromDate: '', toDate: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSearch}
        >
          {({ errors, touched, setFieldValue, handleSubmit, resetForm }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    id="from-date"
                    name="fromDate"
                    label="From Date"
                    type="date"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={touched.fromDate && Boolean(errors.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    id="to-date"
                    name="toDate"
                    label="To Date"
                    type="date"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFieldValue(name, value);
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={touched.toDate && Boolean(errors.toDate)}
                    helperText={touched.toDate && errors.toDate}
                  />
                </Grid>

                {/* Buttons aligned to the right with space between them */}
                <Grid item xs={12} style={{ marginTop: '3%' }}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
                    <Button
                      onClick={handleSubmit}
                      sx={{
                        backgroundColor: '#027BFE',
                        color: 'white',
                        height: '56px',
                        textTransform: 'none',
                        borderRadius: '10px',
                        width: '120px',
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={() => handleClear(resetForm)}
                      sx={{
                        backgroundColor: 'gray',
                        color: 'white',
                        height: '56px',
                        textTransform: 'none',
                        borderRadius: '10px',
                        width: '120px',
                      }}
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>

      {loading && <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>Loading...</Typography>}
      {error && <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>{error}</Typography>}
      {!loading && !error && (

        <Grid sx={{ marginTop: '3%' }}>

          <Grid
            style={{
              display: 'flex',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              maxWidth: '20%',
              borderRadius: 10,
              border: '1px solid rgba(0, 0, 0, 0.3)',
              padding: '1%',
              justifyContent: 'center',
              marginBottom: '2%',
            }}
          >
            <InputBase
              fullWidth
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInput}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearchButtonClick(); // Call your text search function when Enter is pressed
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton backgroundColor='textSecondary'
              onClick={handleSearchButtonClick}>
              <SearchIcon />
            </IconButton>

          </Grid>

          <TableContainer component={Paper} sx={{ marginTop: '2%', maxWidth: '100%', overflowX: 'auto', borderRadius: '12px 12px 0px 0px' }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#027BFE', color: 'white' }}>
                <TableRow>
                  {["Sr. No.", "Receiver Name", "Receiver ID", "Transaction ID", "Date", "Sender Number", "Wallet Opening Balance", "Amount", "Wallet Closing Balance", "Remark", "Transaction Type", "Invoice"].map(header => (
                    <TableCell key={header} sx={{ color: 'white', fontSize: { xs: '9px', sm: '14px' }, fontWeight: '600' }}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={row.tranxid} className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}>
                    <TableCell>{(page * rowsPerPage) + index + 1}</TableCell>
                    <TableCell>{row.senderName}</TableCell>
                    <TableCell>{row.senderId}</TableCell>
                    <TableCell>{row.tranxid}</TableCell>
                    <TableCell>{formatDate(row.date)}</TableCell>
                    <TableCell>{row.senderNumber}</TableCell>
                    <TableCell>{Number(row.wOpeningBalance).toFixed(2)}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{Number(row.wClosingBalance).toFixed(2)}</TableCell>
                    <TableCell>{row.remark}</TableCell>
                    {/* Conditionally set the text color based on transactionType */}
                    <TableCell style={{ color: row.transactionType === 'DEBIT' ? 'red' : row.transactionType === 'CREDIT' ? 'green' : 'inherit' }}>
                      {row.transactionType}
                    </TableCell>
                    <TableCell align="center">
                      <DownloadIcon1
                        onClick={() => handleDownloadPDF(row)} // Trigger PDF download
                        style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                      <Grid id={`receipt-${row.senderId}`}>
                        <FTRReportsInvoice data={row} />
                      </Grid>
                    </Grid>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={Math.ceil(uniqueData.length / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}
          />
        </Grid>
      )}
    </Container>
  );
};

export default NewFundTReport;
