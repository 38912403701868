import React from 'react'
import OthersTopComponent from './OthersTopComponent'
import { Container, TextField, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { PartnerArrowIcon } from '../../res/NewWebIcons';

const ChangeParentNew = () => {
  const formik = useFormik({
    initialValues: {
      oldParentId: '',
      newParentId: '',
    },
    validationSchema: Yup.object({
      oldParentId: Yup.string().required('Old Parent is required')
        .test(
          'not-only-zero',
          'Old Parent cannot be only zero',
          value => value && !/^[0]+$/.test(value)
        ),
      newParentId: Yup.string().required('New Parent is required')
        .test(
          'not-only-zero',
          'New Parent cannot be only zero',
          value => value && !/^[0]+$/.test(value)
        ),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.put('https://api.ipaisa.co.in/myapp/admin/changeParentOfPartners', values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('API Response:', response.data);
        setSubmitting(false);
      } catch (error) {
        console.error('API Error:', error);
        setErrors({ submit: 'An error occurred' });
        setSubmitting(false);
      }
    },
  });

  const handleVerifyUser = async () => {
    try {
      if (!formik.values.oldParentId) {
        formik.setFieldError('oldParentId', 'Please enter the Old Parent');
        return;
      }

      const parentData = {
        oldParentId: formik.values.oldParentId,
      };

      formik.setValues({
        ...formik.values,
        oldParentId: parentData.oldParentId,
      });

      formik.setFieldError('oldParentId', undefined);
    } catch (error) {
      console.error('Verification Error:', error);
    }
  };

  const lineHeight = '56px';

  return (
    <Container style={{ padding: '2rem', marginTop: 'calc(3% + 56px)' }}>
        <Grid>
        <Typography style={{fontWeight:'bold', fontFamily:'sans-serif', fontSize:'20px', color:'#343C6A'}}>Change Parent</Typography>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '1%' }}>
        <Grid item xs={12}>
          <Paper style={{ padding: '2rem', borderRadius:'10px' }}>
            <form onSubmit={formik.handleSubmit}>
              {/* <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={6.1}>
                  <TextField
                    fullWidth
                    id="PartnerId"
                    name="PartnerId"
                    label="Partner ID"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.oldParentId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.oldParentId && Boolean(formik.errors.oldParentId)}
                    helperText={formik.touched.oldParentId && formik.errors.oldParentId}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={2}>
                  <Button
                    sx={{
                      height: lineHeight,
                      width: '100%',
                      fontSize: '15px',
                      textTransform: 'none',
                      borderRadius: '8px',
                      fontWeight: 'bold',
                      backgroundColor: "#FFFFFF",
                      border: '1px solid #027BFE',
                      color: '#027BFE',
                      mt: 1,
                      '&:hover': {
                        backgroundColor: '#027BFE',
                        color: '#FFFFFF',
                      },
                    }}
                    onClick={handleVerifyUser}
                  >
                    Verify User
                  </Button>
                </Grid>
              </Grid> */}

              <Grid spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="oldParentId"
                    name="oldParentId"
                    label="Parent User"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.oldParentId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.oldParentId && Boolean(formik.errors.oldParentId)}
                    helperText={formik.touched.oldParentId && formik.errors.oldParentId}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="newParentId"
                    name="newParentId"
                    label="New Parent"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.newParentId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newParentId && Boolean(formik.errors.newParentId)}
                    helperText={formik.touched.newParentId && formik.errors.newParentId}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-start" style={{ marginTop: '1%' }}>
                <Grid item xs={12} sm={6} md={2}>
                  <Button
                    type="submit"
                    sx={{
                      height: lineHeight,
                      width: '100%',
                      fontSize: '16px',
                      textTransform: 'none',
                      borderRadius: '8px',
                      fontWeight: 'bold',
                      backgroundColor: "#027BFE",
                      color: '#FFFFFF',
                      mt: 1,
                      '&:hover': {
                        backgroundColor: '#027BFE',
                        color: '#FFFFFF',
                      },
                    }}
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? <CircularProgress size={24} /> : 'Assign'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ChangeParentNew;
