import {
  Box,
  Button,
  Container,
  Grid2,
  Input,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const VirtualAccountReady = ({ virtualAccount }) => {
  return (
    <>
      <Paper elevation={2} style={{ padding: "2rem", marginTop: 0.5 }}>
        <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
          Queen QR Details
        </Typography>
        <Stack spacing={1}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>Here is Your Qr Code</Typography>
            <img
              src={virtualAccount.upi_qrcode_remote_file_location}
              style={{ height: "200px", width: "200px" }}
            />
          </Box>
          <Box sx={{ marginTop: 3, marginBottom: 2 }}>
            <Grid2 container spacing={1}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>Account Label</label>
                <Typography>{virtualAccount.label}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>
                  Virtual Account Number
                </label>
                <Typography>{virtualAccount.virtual_account_number}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>QR Link</label>

                <Link
                  href={virtualAccount.upi_qrcode_scanner_remote_file_location}
                >
                  <Button variant="contained" sx={{ marginLeft: 2 }}>
                    Download QR
                  </Button>
                </Link>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>
                  Virtual UPI Handle/Id
                </label>
                <Typography>{virtualAccount.virtual_upi_handle}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>Expire Date</label>
                <Typography>{virtualAccount.auto_deactivate_at}</Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <label style={{ fontWeight: "bold" }}>
                  Is Account Active {virtualAccount.is_active}
                </label>
                {console.log(virtualAccount.is_active)}
                <Typography>
                  {virtualAccount.is_active ? "True" : "False"}
                </Typography>
              </Grid2>
            </Grid2>
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

export default VirtualAccountReady;
