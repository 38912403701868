import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CardMedia,
  Grid2,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  InputAdornment,
} from "@mui/material";
import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png"; // Assuming same logo
import logo from "../Assets/login/iPaisaLogo.png"; // Assuming same iPaisa logo
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";
import Visa from "./Credit Cards and Payment Methods Icons/Payment Method/Visa.png";
import Mastercard from "./Credit Cards and Payment Methods Icons/Payment Method/Mastercard.png";
import Rupay from "./Credit Cards and Payment Methods Icons/Payment Method/Rupay.png";
import Maestro from "./Credit Cards and Payment Methods Icons/Payment Method/Maestro.png";
import Amex from "./Credit Cards and Payment Methods Icons/Payment Method/Amex.png";
import CreditCardBillPaymentsList from "./CreditCardBillPaymentsList";
import Swal from "sweetalert2";

const CreditCard = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [binInfo, setBinInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentResult, setPaymentResult] = useState("");
  const [isCardVerified, setIsCardVerified] = useState(true);
  const [cardProvider, setCardProvider] = useState(""); // Card provider (e.g., Visa, MasterCard)
  const [shouldRefresh, setShouldRefresh] = useState(false); // State to control refreshing
  const [formData, setFormData] = useState({
    cardName: "",
    cardNumber: "",
    cAmount: "",
  });
  const [totalPages, setTotalPages] = useState();

  // Common method to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // If the field is "cAmount", ensure it contains only numbers
    if (name === "cAmount" && !/^\d*$/.test(value)) {
      return; // Prevent non-numeric input
    }

    if (name === "cardNumber" && !/^\d*$/.test(value)) {
      return; // Prevent non-numeric input
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Trigger BIN check when 6 digits are entered for card number
    if (name === "cardNumber" && value.length === 6) {
      fetchBINData(value.slice(0, 6));
    }
  };

  const fetchBINData = async (number) => {
    setLoading(true);
    try {
      const jwtTokentopass = localStorage.getItem("jwtToken");

      const response = await axios.post(
        `https://api.ipaisa.co.in/myapp/creditcard/binchecker`,
        {
          binNumber: number,
          latitude: 18.5204,
          longitude: 73.8567,
        },
        {
          headers: { Authorization: `Bearer ${jwtTokentopass}` },
        }
      );
      console.log(response.data);
      const binData = response.data.data.binDetails;
      setBinInfo(binData);

      // Check if it's a valid card type (Visa, MasterCard)
      if (binData.cardType === "CREDIT") {
        // console.log(binData.cardNetwork);
        setIsCardVerified(false); // Enable pay button once card is verified
        setCardProvider(binData.cardNetwork); // Set card provider (Visa, MasterCard)
      } else {
        setIsCardVerified(true); // Invalid card type, disable pay button
        setCardProvider(""); // Clear card provider if not valid
      }
    } catch (error) {
      console.error("Error fetching BIN data", error);
      setIsCardVerified(true);
      setCardProvider(""); // Clear card provider on error
    } finally {
      setLoading(false);
    }
  };

  // Function to get user's location
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // console.log("Geolocation success:", { latitude, longitude });
            // Limit to 4 decimal places
            const roundedLatitude = parseFloat(latitude.toFixed(4));
            const roundedLongitude = parseFloat(longitude.toFixed(4));

            setLatitude(roundedLatitude);
            setLongitude(roundedLongitude);
          },
          (error) => {
            console.error("Geolocation error:", error);
            // If geolocation fails, use fallback location
            Swal.fire({
              title: "Error while fetching location",
              text: error.message,
              icon: "error",
            });
            setLatitude(0);
            setLongitude(0);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
        // Fallback if geolocation is not supported

        Swal.fire({
          title: "Error while fetching location",
          text: "Something Went Wrong..",
          icon: "error",
        });
        setLatitude(0);
        setLongitude(0);
      }
    });
  };
  // Function to process payment on Pay button click
  const handlePayment = async () => {
    // console.log(latitude);
    // console.log(longitude);
    // Validation rules
    if (!formData.cardName) {
      Swal.showValidationMessage(<></>);
      toast.error("Cardholder name is required.");
      return;
    }

    if (!formData.cardNumber || formData.cardNumber.length !== 16) {
      toast.error("A valid 16-digit card number is required.");
      return;
    }

    if (
      !formData.cAmount ||
      isNaN(formData.cAmount) ||
      Number(formData.cAmount) <= 0
    ) {
      toast.error("Please enter a valid bill amount.");
      return;
    }
    if (
      latitude === null ||
      (latitude === 0 && longitude === null) ||
      longitude === 0
    ) {
      getUserLocation();
      // handlePayment();
    } else {
      setLoading(true);
      const jwtTokentopass = localStorage.getItem("jwtToken");
      setPaymentLoading(true);

      console.log(formData.cardName);
      try {
        const response = await axios.post(
          "https://api.ipaisa.co.in/myapp/creditcard/cardpayment",
          {
            name: formData.cardName,
            accountNumber: formData.cardNumber,
            transferAmount: formData.cAmount,
            latitude: latitude,
            longitude: longitude,
            provider: "InstantCC",
          },
          {
            headers: { Authorization: `Bearer ${jwtTokentopass}` },
          }
        );
        console.log(response.data);
        if (response.status === 200) {
          if (response.data.message === "Transaction Completed..!!") {
            setPaymentResult("Payment Successful"); // You can handle the response as needed
            setSubmitSuccess(true);
            setShouldRefresh((prev) => !prev); // Toggle shouldRefresh to trigger data re-fetch
            Swal.fire({
              title: "Credit Card Bill Payment",
              text: response.data.message,
              icon: "success",
              timer: 4000,
            });
          } else {
            // toast.warn(response.data.message);
            Swal.fire({
              title: response.data.message,
              text: response.data.Issue,
              icon: "info",
              timer: 4000,
            });
          }
          setShouldRefresh((prev) => !prev); // Toggle shouldRefresh to trigger data re-fetch
          setLoading(false);
        } else {
          Swal.fire({
            title: response.data.message,
            text: response.data.Error,
            icon: "error",
            timer: 4000,
          });
        }
        setShouldRefresh((prev) => !prev); // Toggle shouldRefresh to trigger data re-fetch
        setLoading(false);
      } catch (error) {
        console.error("Payment failed", error);
        setPaymentResult("Payment Failed");
        setLoading(false);
        Swal.fire({
          title: error.response.data.message,
          text: error.response.data.Error,
          icon: "error",
          timer: 4000,
        });
      } finally {
        setPaymentLoading(true);
        setLoading(false);
      }
    }
  };

  // Function to get the correct logo based on the card provider
  const getCardLogo = () => {
    switch (cardProvider) {
      case "VISA":
        return <img src={Visa} alt="Visa" />;
      case "MASTERCARD":
        return <img src={Mastercard} alt="MasterCard" />;
      case "RUPAY":
        return (
          <img src={Rupay} alt="RupayCard" style={{ height: 24, width: 28 }} />
        );
      case "MAESTRO":
        return <img src={Maestro} alt="MasstroCard" />;
      case "AMERICAN EXPRESS":
        return <img src={Amex} alt="AmexCard" />;
      default:
        return; // Default card logo
    }
  };

  useEffect(() => {
    getUserLocation();
    let timer;
    if (submitSuccess) {
      timer = setTimeout(() => {
        setSubmitSuccess(false);
      }, 8000); // 10 seconds
    }
    return () => clearTimeout(timer);
  }, [cardProvider]);

  return (
    <Container maxWidth="lg" style={{ marginTop: "12%", marginBottom: "3%" }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        bgcolor="#DDF1F5"
        borderRadius={8}
        sx={{
          background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
          color: "white",
        }}
      >
        <Grid2 container rowSpacing={1}>
          <Grid2 size={{ md: 6 }} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                marginBottom: "4%",
              }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" },
              }}
            />
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", fontSize: "35px" }}
              gutterBottom
            >
              Secure Credit Card Bill Payment
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontSize: "20px", marginTop: "5%" }}
            >
              Easily pay your credit card bills and avoid late fees with our
              secure platform.
            </Typography>
          </Grid2>
          <Grid2 size={{ md: 6 }}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography
                variant="h5"
                color="#027BFE"
                gutterBottom
                sx={{ fontWeight: "bold", fontSize: "30px" }}
              >
                Credit Card Bill Payment
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <img
                  src={BBPSLogo}
                  alt="BBPS Logo"
                  style={{ marginLeft: "25.5rem", width: "16%", height: "24%" }}
                />
              </Box>
              <TextField
                label="Enter Name on Card"
                name="cardName"
                value={formData.cardName}
                onChange={handleInputChange}
                sx={{ width: "100%" }}
              ></TextField>

              <TextField
                label="Enter Credit Card Number"
                name="cardNumber"
                value={formData.cardNumber}
                onChange={handleInputChange}
                sx={{ width: "100%", marginTop: "2%" }}
                inputProps={{ maxLength: 16 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {getCardLogo()}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <TextField
                label="Enter Bill Amount"
                name="cAmount"
                value={formData.cAmount}
                onChange={handleInputChange}
                sx={{ width: "100%", marginTop: "2%" }}
              ></TextField>
              <LoadingButton
                fullWidth
                type="submit"
                onClick={handlePayment}
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: "#027BFE",
                  marginTop: "2%",
                }}
                disabled={isCardVerified}
              >
                Confirm Payment
              </LoadingButton>
              {submitSuccess && (
                <Typography
                  variant="body1"
                  style={{ marginTop: "0.5rem", color: "green" }}
                >
                  Payment successfully submitted!
                </Typography>
              )}
            </Box>
          </Grid2>
        </Grid2>
      </Box>
      <Box>
        <Typography variant="h4" sx={{ margin: 2 }}>
          Your Last 10 Credit Card Bill Payments
        </Typography>

        <CreditCardBillPaymentsList
          shouldRefresh={shouldRefresh}
          pageSize={10}
        />
      </Box>
    </Container>
  );
};
export default CreditCard;
