import React, { useState } from 'react';
import { Box, Button, Paper, Grid } from '@mui/material';
import SinglePayout from './SinglePayout';
import BulkPayout from './BulkPayout';

const Payout = ({ walletBalance, bankDetails, payoutModes, bulkPayout, inputHeight }) => {
    const [showBulkPayout, setShowBulkPayout] = useState(false);

    return (
        <Grid container spacing={2} style={{ marginTop: '3%' }}>
            <Grid item xs={12} md={4}>
                {/* Display Wallet Balance */}
                <Paper style={{ padding: '1rem' }}>
                    <h3>Wallet Balance: {walletBalance}</h3>
                </Paper>
            </Grid>

            <Grid item xs={12} md={8}>
                <Box display="flex" justifyContent="center" mb={3}>
                    <Button
                        variant={showBulkPayout ? "contained" : "outlined"}
                        onClick={() => setShowBulkPayout(false)}
                    >
                        Single Payout
                    </Button>
                    {bulkPayout && (
                        <Button
                            variant={showBulkPayout ? "outlined" : "contained"}
                            onClick={() => setShowBulkPayout(true)}
                        >
                            Bulk Payout
                        </Button>
                    )}
                </Box>

                <Paper style={{ padding: '1rem' }}>
                    {showBulkPayout ? (
                        <BulkPayout inputHeight={inputHeight} />
                    ) : (
                        <SinglePayout
                            bankDetails={bankDetails}
                            payoutModes={payoutModes}
                            inputHeight={inputHeight}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Payout;
