import { Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import React from "react";
import { Walllet_Icon } from '../../res/icons';


const WalletBalanceComponent = ({ walletBalance }) => {

  const formattedBalance = walletBalance !== undefined && walletBalance !== null ? walletBalance.toFixed(2) : "0.00";

  return (
    <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
      <Paper style={{ padding: '2rem' }}>
        <Card
          style={{
            width: "100%",
            height: "280px",
            borderRadius: "12px",
            alignContent: "center",
            textAlign: 'center',
            backgroundImage: "linear-gradient(220.55deg, #547AFF 25.12%, #413DFF 70.43%)",
          }}
        >
          <CardContent>
            <Walllet_Icon />
            <Typography
              color="textSecondary"
              sx={{ color: "#FFFFFF", fontSize: "22px" }}
              gutterBottom
            >
              Wallet Balance
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: "#FFFFFF", fontSize: "30px" }}
              component="h2"
            >
              ₹ {formattedBalance}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  );
};

export default WalletBalanceComponent;
